@import "assets/styles/main";

.multiple-select {
  width: 97%;

  input[type="checkbox"] {
    appearance: none;
    background-color: $color-white;
    cursor: pointer;
    color: $color-gray-two;
    width: 1.6rem;
    height: 1.6rem;
    border: 0.1rem solid $color-gray-two;
    transform: translateY(-0.075rem);
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 1rem;
    height: 1rem;
    transform: scale(0);
    transition: 50ms transform ease-in-out;
    box-shadow: inset 1rem 1rem $color-secondary;
    border: 0.1rem solid $color-gray-two;
  }

  input[type="checkbox"]:checked {
    border: 0.1rem solid $color-secondary;
    background: $color-white 0% 0% no-repeat padding-box;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }

  &__error {
    color: $color-danger;
    font-size: 1.8rem;
    font-family: Roboto, sans-serif;
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
  }

  .child-inputs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    margin-left: 2.8rem;

    &__input-container {
      grid-column: 1 / span 3;

      .input__wrapper {
        margin-left: 0;
      }

      :disabled {
        background-color: $color-inactive;
        border: 0.1rem solid #f3f3f3;
        opacity: 0.5;
        pointer-events: none;
        transition: opacity 0.3s ease, pointer-events 0.3s ease;
      }

      :not(:disabled) {
        opacity: 1;
        pointer-events: auto;
      }
    }

    &__checkbox-container:last-child {
      grid-column: 1 / span 3;
    }
  }

  .grandchild-inputs {
    &__input-container {
      .input__wrapper {
        margin-left: 0;
      }

      :disabled {
        background-color: $color-inactive;
        border: 0.1em solid #f3f3f3;
        opacity: 0.5;
        pointer-events: none;
        transition: opacity 0.3s ease, pointer-events 0.3s ease;
      }

      :not(:disabled) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &-button {
    width: 30.4rem;
    margin-left: 0;
    justify-self: start;
    margin-top: 2.3rem;
  }
}

@include styles-for-device-size(md) {
  .multiple-select {
    min-height: 57vh;

    .form__wrapper {
      width: 100%;
    }

    .child-inputs__checkbox-container {
      grid-column: 1 / span 3;
    }

    &-button {
      width: 100%;
    }
  }
}
