@import "assets/styles/main";

.multiple-input {
  width: 100%;

  .form__wrapper {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1rem;
    max-width: 63.8rem;

    .full-address {
      grid-row: 5 / 6;
    }

    .input-container {
      .input-label {
        margin-left: 0;
      }

      .input__wrapper {
        margin-left: 0;
      }
    }

    .radioButtonGroup {
      margin-top: 4.5rem;
      margin-bottom: 2rem;
    }

    .multiple-input-button {
      margin-left: 0;
      justify-self: start;
      margin-top: 2.3rem;
      width: 30.4rem;

      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }

    .month-and-year-select {
      display: flex;
      justify-content: space-between;

      .month-select,
      .year-select {
        width: 100%;
      }
    }

    .disclaimer {
      margin-top: 0;
      grid-column: 1 / span 2;
    }
  }

  .dropdown-container {
    .dropdown-items {
      width: 12.3rem;
      max-height: 12.3rem;
      border-radius: 1rem;
    }

    ::-webkit-scrollbar {
      display: none;
    }
  }

  @include styles-for-device-size(md) {
    .form__wrapper {
      width: 100%;
    }
  }

  @include styles-for-device-size(sm) {
    min-height: 60vh;

    .form__wrapper {
      grid-template-columns: repeat(1, 1fr);

      .input__wrapper {
        margin: 1rem 0;
      }

      .radio-button-group-container {
        margin-bottom: 1.6rem;

        .radioButtonGroup {
          margin-bottom: 0;
          margin-top: 0;
        }
      }

      .full-address {
        grid-column: 1 / span 2;
      }

      .disclaimer {
        grid-column: 1 / span 1;
        margin-bottom: 2.4rem;
      }

      .multiple-input-button {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.checkbox {
  .form__wrapper {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 5rem, repeat(4, 1fr);
  }
}

.singleColumn {
  .form__wrapper {
    grid-template-columns: repeat(1, 1fr);

    .dateinput-container {
      grid-column: 1/span 2;
    }
  }
}

.employer-income {
  .form {
    &__wrapper {
      display: grid;
      grid-template-columns: none;
      max-width: 30.4rem;

      .total-display {
        font-size: 1.6rem;
        color: $color-primary;
        font-family: Poppins-Medium, sans-serif;
        display: flex;
        justify-content: center;
        margin-top: 1.2rem;
      }
    }
  }
}

@include styles-for-device-size(sm) {
  .employer-income {
    .form {
      margin: 0;

      &__wrapper {
        .currency-container {
          margin-bottom: 2.4rem;

          .currency {
            margin-top: 0;
          }
        }

        .total-display {
          margin-top: 0;
          margin-bottom: 2.4rem;
        }
      }
    }
  }
}

.col {
  .form__wrapper {
    grid-template-columns: none;
  }
}