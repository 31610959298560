@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/variables";

.thankyou {
  margin-top: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;

    &__header {
      font-size: 4.8rem;
      font-family: Poppins-medium, sans-serif;
      color: $color-dark-black;
    }

    &__sub-header {
      align-self: center;
      width: 70%;
      margin-top: 0.8rem;
      font-size: 1.8rem;
      font-family: Roboto-regular, sans-serif;
      color: #3b6ccf;
    }
  }

  @include styles-for-device-size(xs) {
    &-wrapper {
      padding: 0 1.6rem;
      width: 100%;

      &__header {
        font-size: 2.4rem;
      }

      &__sub-header {
        width: 100%;
        font-size: 1.6rem;
      }
    }
  }
}