@import "assets/styles/main";

.single-input {
  min-width: 40%;

  .form__wrapper__withCheckbox {
    flex-direction: column;
    width: 100%;
  }

  .form__wrapper__additionalButton button {
    margin-top: 3.2rem;
    margin-right: 1.6rem;
  }

  .form__wrapper {
    margin: 0;
    width: 50%;
    flex-direction: column;
    align-items: flex-start;

    .input-container {
      width: 30.4rem;
      flex: 9;
      margin-bottom: 1rem;

      .input-label {
        margin-left: 0;
      }

      .input__wrapper {
        margin-bottom: 0;
        margin-left: 0;

        .input {
          font-size: 1.4rem;
          width: 30.4rem;
        }
      }
    }

    .dropdown-container {
      width: 30.4rem;
    }

    .single-input-button {
      flex: 1;
      min-height: 5.1992rem;
      padding: 0 3rem;
      margin: 2rem 0 0;
      width: 30.4rem;

      &.has-error {
        margin-bottom: 2.25rem;
      }

      &:not(:last-child) {
        margin-right: 1.6rem;
      }
    }
  }

  @include styles-for-device-size(xl) {
    min-width: 50%;
  }

  @include styles-for-device-size(lg) {
    min-width: 60%;
  }

  @include styles-for-device-size(md) {
    min-width: 75%;
  }

  @include styles-for-device-size(sm) {
    min-width: 90%;
  }

  @include styles-for-device-size(xs) {
    min-width: 100%;
    min-height: 58vh;

    .form__wrapper {
      width: 100%;
      flex-direction: column;

      .input-container {
        width: inherit;

        .input__wrapper {
          margin-right: 0;

          .input {
            width: 100%;
            font-size: 1.6rem;
          }
        }
      }

      .dropdown-container {
        width: 100%;
      }

      .single-input-button {
        width: 100%;
        margin: 1.6rem 0;
      }
    }
  }
}
