@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.loan-officer-highlight {
  padding: 2.4rem 0 0 14.2rem;

  .logo-and-rating {
    display: flex;
    gap: 4rem;
  }

  .newamerican-funding-logo {
    margin-right: 4rem;
    width: 25.1rem;
    height: 4.852rem;
  }

  .corp-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 70%;
  }

  .personal-details {
    flex: 30%;
    display: flex;
    justify-content: end;

    &__portrait {
      border-radius: 50%;
      height: 10rem;
      width: 10rem;
      object-fit: cover;
    }

    .no-image-url {
      background-color: $color-off-white;
      position: relative;

      &__icon {
        width: 4.4rem;
        height: 5rem;
        color: $color-gray-bg2;
        position: absolute;
        top: 22%;
        right: 28%;
      }
    }

    &__information {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 1rem 0 1.3rem;
    }

    &__name {
      font-size: 2rem;
      white-space: nowrap;
    }

    &__contact-info {
      text-align: left;
      position: relative;

      .icon-envelope {
        font-size: 2rem;
      }

      .icon-phone {
        font-size: 1.8rem;
      }
    }
  }

  .rating {
    display: flex;
    flex-direction: column;

    &-company-logo {
      margin-right: 6.3rem;
      width: 13.3rem;
      font-size: 1.4rem;
      height: 2rem;
    }

    .overall-text {
      font-size: 1.4rem;
      text-align: left;
      margin: 0.5rem 0;
    }
  }

  .footer-links {
    font-size: 1.2rem;
    color: $color-tertiary-blue;
    text-align: left;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
    color: $color-tertiary-blue;
  }


  .reviews {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &__ratings {
      text-align: left;
    }

    &__stars {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      * {
        margin-right: 0.5rem;
      }
    }

    .no-review-button {
      height: 3.4rem;
    }
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 0.4rem;
    color: $color-white;
  }

  .loan-officer-button {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: 600;

    &:hover {
      background-color: $color-white;
      color: $color-primary;
    }
  }

  @include styles-for-device-size(sm) {
    flex-direction: column;
    padding: 1.6rem 0 0 1.6rem;
    height: 34.3rem;

    .corp-details {
      order: 2;
      margin-top: 2rem;

      .logo-and-rating {
        flex-direction: column;
        gap: 1rem;
      }
    }

    p {
      font-size: 1.3rem;
      margin-right: 0.5rem;
    }

    p.nmls {
      font-size: 1.2rem;
    }

    .newamerican-funding-logo {
      margin-bottom: 1rem;
      width: 19.586rem;
      height: 3.786rem;
    }

    .rating {
      flex-direction: column;

      &-company-logo {
        text-align: left;
      }


      .rating-text {
        display: none;
      }
    }

    .personal-details {
      order: 1;
      border-bottom: 0.3rem solid $color-dark-blue;
      padding-bottom: 1rem;
      justify-content: start;

      &__portrait {
        height: 7.4rem;
        width: 7.4rem;
        object-fit: cover;
      }

      .no-image-url {
        &__icon {
          width: 3.4rem;
          height: 3.9rem;
        }
      }

      &__information {
        border-right: 0;
      }

      &__contact-info>span {
        margin-right: 1rem;
      }

      &__name {
        font-size: 1.6rem;
        font-family: Poppins-Medium;
      }
    }

    .footer-links {
      margin-bottom: 2rem;
    }

    .loan-officer-button {
      width: 47%;
      font-size: 10px;
      margin-bottom: 1rem;
    }

    .reviews {
      flex-direction: row;

      &__ratings {
        display: flex;
        align-items: flex-end;
        margin-bottom: 0.5rem;
      }

    }
  }
}

.footerContainer{
  display: flex;
  justify-content: center;
  background-color: $color-primary;
}

.footer--info, .document-footer--info {
  background-color: $color-primary;
  height: fit-content;
  color: $color-white;
  display: flex;
  padding: 2.4rem 0 1.6rem;
  border-radius: 1rem 1rem 0 0;

  &.no-lo-section {
    flex-direction: column;
  }

  .no-loan-officer {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &--info {
      text-align: left;

      .contact {
        color: $color-white;
        line-height: 1.6rem;
        font-size: 1.2rem;
        margin-bottom: 2.4rem;
        font-family: Roboto-Regular, sans-serif;
      }

      .btn-contact {
        display: block;
        font-size: 1rem;
        font-weight: $font-weight-semi-bold;
        min-height: 2.5rem;
        min-width: 10.5rem;
        max-height: 2.5rem;
        max-width: 10.5rem;
        padding: .5rem 2rem;
        margin: 2.5rem 0;
        border-radius: 2rem;
        color: $color-navy-blue;

        &:hover {
          background-color: $color-white;
        }
      }
    }

    .newamerican-funding-logo {
      margin-bottom: 1.6rem;
      width: 24.5rem;
      height: 4.7rem;
    }

    .rating {
      display: flex;
      flex-direction: column;

      p {
        font-size: 1.2rem;
        text-align: left;
      }

      &-company-logo {
        width: 13rem;
        height: 2rem;
        margin-bottom: 0.5rem;
      }
    }

    .reviews {
      &__ratings {
        display: flex;
        align-items: flex-end;
        margin: 0.5rem 0;
      }

      &__stars {
        font-size: 1.4rem;
      }
    }
  }

  .links {
    font-size: 1.2rem;
    color: $color-tertiary-blue;
    text-align: left;
    font-family: Roboto-Regular, sans-serif;

    a:link,
    a:visited {
      text-decoration: none;
      color: $color-tertiary-blue;
    }
  }

  @include styles-for-device-size(sm) {
    border-radius: 0;
    padding: 1.6rem 1.6rem 0;

    .footer--info{
      width: 100%;
    }

    .no-loan-officer {
      flex-direction: column;

      &--info {
        .contact {
          margin: 2.4rem 0;
        }
      }

      .newamerican-funding-logo {
        margin-bottom: 0;
        width: 19.5rem;
        height: 3.8rem;
      }
    }

    .links {
      margin: 3rem 0 2.4rem;
      text-align: left;
    }

    .rating-text {
      display: none;
    }
  }
}

.document-footer--info{
  width: 128rem;
}

.footer--info{
  width: 115rem;
}