@import "assets/styles/main";

.external-navigation {
  .loader {
    width: 100%;
    height: 100%;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $color-pure-white;
    font-family: Poppins, sans-serif;

    &__header {
      margin: 0 0 2.4rem;
      font-size: 2.4rem;
      font-weight: $font-weight-semi-bold;
      max-width: 51.5rem;
    }

    &__message1,
    &__message2 {
      color: $color-black;
      margin-top: 2.4rem;
      line-height: 2.4rem;
    }

    &__message1 {
      font-size: 1.8rem;
      font-weight: bold;
      max-width: 32rem;
    }

    &__message2 {
      font-size: 1.6rem;
      font-weight: 400;
      margin-top: 1.6rem;
    }
  }

  @include styles-for-device-size(sm) {
    .loader {
      &__header,
      &__message1,
      &__message2 {
        max-width: 28.4rem;
      }

      &__header {
        font-size: 1.8rem;
        max-width: 30.4rem;
      }

      &__message1 {
        font-size: 1.6rem;
      }

      &__message2 {
        font-size: 1.4rem;
        margin-bottom: 12rem;
      }
    }
  }
}
