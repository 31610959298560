@import "assets/styles/main";

.close-banner {
  margin-left: 1rem;
  font-size: 1.8rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.privacy-link {
  color: $color-white;
}

@include styles-for-device-size(xs) {
  .close-banner {
    margin-bottom: 2.9rem;
    margin-left: 0;
    margin-right: 0.8rem;
    font-size: 1.4rem;
  }

  .formatted-text {
    display: block;
  }
}

.text--login-page {
  color: $color-primary;
  font-size: 1.4rem;
  padding: 1.25rem 2rem;

  &__link {
    color: $color-primary;
    font-size: 1.4rem;
    padding: 0;
  }
}
