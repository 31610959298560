/* Brand colors */
$color-primary: #091644;
$color-secondary: #3b6ccf;
$color-tertiary-blue: #889bd8;

/* Functional colors */
$color-danger: #d62516;
$color-warning: #edac1b;
$color-success: #288034;
$color-inactive: #d9d9d9;
$color-info: blue;
$color-remove: #CF1D1D;

/* Background Colors */
$color-gray-bg: #676a7a;
$color-gray-bg2: #a3a6b8;
$color-light-gray-bg: #d1d4e3;
$color-light-red-bg: #efe0df;

/* Other colors */
$color-light-gray: #d9d9d9;
$color-light-black: #333;
$color-light-black-bg: #333333b3;
$color-light-gray-two: #ebe8e8;
$color-light-gray-three: #d2d5e4;
$color-blue: #0095d0;
$color-dark-gray: #424549;
$color-dark-gray-secondary: #676A7A;
$color-light-blue-secondary: #3b6ccf;
$color-black: #000;
$color-white: white;
$color-gray: #777;
$color-gray-two: #707070;
$color-off-white: #f8f9ff;
$color-light-blue: #f5f8ff;
$color-yellow: #fabf6f;
$color-dark-blue: #313d7a;
$color-dark-black: #1d1f2a;
$color-black-font: #222;
$color-light-black-dd: #333;
$color-black-dd: #1c1c1c;
$color-light-blue-primary: #F1F4F6;
$color-navy-blue: #0C1A60;
$color-black-shadow: #00000029;
$color-pure-white: #FFF;
$color-transparent-gray: #3333334D;
$color-translucent-black: #00000040;

/* Font weights */
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-medium: 500;
$font-weight-normal: 400;