@import "assets/styles/main";

.property {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 45%;
  margin: 3.4rem auto 2.2rem;

  &-address {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.8rem;
    justify-content: center;
    line-height: 1.3;

    &.twoLinesDisplay {
      flex-direction: column;
    }

    &--primary {
      max-width: 740px;

      svg {
        font-size: 2.4rem;
        color: $color-primary;
        margin-right: 0.8rem;
        padding: 0.3rem;
      }

      p {
        font-size: 3.2rem;
        color: $color-primary;
        font-family: poppins-Semibold, sans-serif;
      }
    }

    &__address {
      font-size: 3.2rem;
      color: $color-primary;
      font-family: poppins-Semibold;
    }

    &__icon {
      font-size: 2.4rem;
      color: $color-primary;
      margin-right: 0.8rem;
    }

    &--secondary > span {
      font-size: 3.2rem;
      font-family: Poppins;
      color: $color-primary;
      margin-left: 1rem;
    }
  }

  &-loan {
    display: flex;
    justify-content: center;
    column-gap: 2.4rem;
    color: $color-light-black;
    text-align: center;

    &-number,
    &-amount,
    &-type {
      display: flex;
      align-items: baseline;
    }

    &__label {
      font-size: 1.4rem;
      font-family: Roboto;
      color: $color-dark-black;
      margin-right: 0.3rem;
      text-transform: uppercase;
    }

    &__data {
      font-size: 1.6rem;
      font-family: Poppins-SemiBold;
      color: $color-dark-black;
    }
  }

  @include styles-for-device-size(sm) {
    width: 90%;
    margin: 2.2rem auto 0;

    &-address {
      flex-direction: column;
      line-height: 1.2;

      &__address {
        font-size: 2rem;
      }

      &--primary {
        display: flex;
        flex-direction: column;

        svg {
          padding: 0;
        }
      }

      &--secondary > span {
        font-size: 2rem;
      }

      &--secondary {
        margin-bottom: 1rem;
      }

      &__icon {
        font-size: 2.4rem;
        margin-right: 0;
        margin-bottom: 0.8rem;
      }
    }

    &-loan {
      &-number,
      &-amount,
      &-type {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__label {
        margin-right: 0;
      }
    }
  }
}
