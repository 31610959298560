@import "assets/styles/main";

.information {
  width: 65%;
  margin-bottom: 1.6rem;

  &__paragraph {
    margin-bottom: 4.2rem;

    .timer_paragraph-container {
      display: flex;
      height: fit-content;
      margin-bottom: 1.6rem;

      .timer-icon {
        font-size: 2rem;
        margin-right: 0.8rem;
        color: #3b6ccf;
      }

      .timer_paragraph {
        font-size: 1.4rem;
        font-family: Poppins, sans-serif;
        margin-bottom: 0;
      }
    }
  }

  .informational-button {
    width: 30.4rem;
    margin: 0;
  }

  p {
    font-size: 1.8rem;
    margin-bottom: 1.6rem;
    color: $color-dark-black;
  }

  ul {
    margin-left: 2.4rem;
    font-size: 1.6rem;
    font-family: Roboto, sans-serif;

    li {
      margin-bottom: 0.8rem;
    }
  }

  @include styles-for-device-size(sm) {
    min-width: 100%;
    min-height: 57vh;

    p {
      font-size: 1.6rem;
    }

    .informational-button {
      width: 100%;
    }

    .paragraph-container {
      margin-left: -1rem;
    }

    .disclaimer{
      width: 113%;
      margin-left: -2rem;
    }
  }
}
