@import "assets/styles/main";

.currency-container {
  margin-bottom: 1.2rem;
  width: 100%;

  .currency {
    height: 5.2rem;
    width: 100%;
    color: black;
    border: 1px solid #091644;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    padding-left: 1rem;
    font-size: 1.6rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0.5rem;
      position: relative;
      width: 30.4rem;
    }

    &.currency--error {
      border-color: $color-danger;
      border-radius: 5px;

      &:focus {
        outline: none;
        border: 1px solid $color-danger;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $color-light-blue-secondary;
    }

    .currency-label {
      margin-left: 10px;
      font-family: Poppins-Medium, sans-serif;
      font-size: 1.4rem;
      color: $color-primary;

      &__wrapper {
        width: 100%;
        display: flex;
        align-items: baseline;
      }
    }
  }
  @include styles-for-device-size(sm) {
    .currency {
      &__wrapper {
        width: 100%;
      }
    }
  }
}
