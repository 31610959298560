@import "assets/styles/main";
@import "pages/application/application";

$progressbar-left-padding: 8rem;

.progressBarContainer{
  display: flex;
  justify-content: center;
  background-color: $color-off-white;
}

.progress-bar {
  width: calc(100% - (2 * $progressbar-left-padding));
  max-width: 128rem;
  min-height: 1rem;
  background-color: $color-light-gray-bg;
  position: relative;

  &__shaded-progress {
    height: 100%;
    background-color: $color-secondary;
    transition: width 2s ease;
  }

  &__shaded-progress-label {
    position: absolute;
    left: 0;
    top: -.5rem;
    border-radius: 0.5rem;
    width: 3.8rem;
    font-family: Roboto, sans-serif;
    height: 2.4rem;
    background-color: $color-secondary;
    color: white;
    transition: left 2s ease;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  @include styles-for-device-size(sm) {
    width: 100%;
    margin-left: 0;
  }
}
