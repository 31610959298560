@import "assets/styles/main";

.conditionsContainer,
.generic-condition {
  margin-top: 2.4rem;
  width: 100%;

  .card {
    &__header {
      p {
        margin: 2.4rem 1rem 1.8rem 0;
      }
    }
  }
}

.generic-condition {
  .card {
    &__header {
      p {
        margin: 2.4rem 0;
      }
    }

    &__body {
      height: 31.8rem;

      .naf-insurance {
        margin-top: 2.6rem;
        align-items: center;

        &__left-wrapper {
          align-items: center;
        }
      }
    }
  }
}

.dashboard-content {
  align-self: flex-start;
  margin-bottom: 4rem;

  &__text {
    font-size: 1.6rem;
    font-family: Roboto-Regular, sans-serif;
    margin-top: 0.8rem;
    text-align: left;
  }
}

@include styles-for-device-size(sm) {
  .conditionsContainer {
    display: flex;
    justify-content: center;

    .card {
      &__header {
        p {
          font-size: 1.8rem;
          width: auto;
        }
      }
    }
  }

  .generic-condition {
    margin: 4.2rem 0 0 1.6rem;

    .card {
      &__header {
        p {
          margin: 2.4rem 0;
        }
      }

      &__body {
        height: 48.2rem;

        .naf-insurance {
          margin-top: -1rem;

          &__content {
            color: $color-dark-black;
          }
        }
      }
    }
  }

  .dashboard-content {
    margin-left: 1rem;

    &__text {
      font-size: 1.4rem;
    }
  }

  .dashboard-uqal-banner {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 .5rem;
  }
}
