@import "assets/styles/main";

.notify-popup-container {
  top: 7rem;
  position: absolute;
  right: 29rem;
  display: flex;
  width: 31.2rem;  
  padding: 1.6rem 2.4rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 0.6rem;
  border: 0.1rem solid $color-light-gray-bg;
  background: $color-pure-white;
  box-shadow: 0.4rem 0 0.4rem 0.2rem $color-black-shadow;
  z-index:3;

  .notifiy-scroll {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .notifiy-popup-wrapper {
    display: flex;
    padding: 0.8rem 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.4rem;
    align-self: stretch;
    width: 26.4rem;
    border-bottom: 0.1px solid $color-light-gray-bg;

    &__section {
      flex: 1 0 0;
      color: $color-gray-bg;
      font-family: Poppins-Medium, sans-serif;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.8rem;
    }

    @include styles-for-device-size(md) {
      &__name {
        font-size: 1.6rem;
      }
    }

      &__name {
        align-self: stretch;
        color: $color-primary;
        font-family: Poppins-Medium, sans-serif;
        font-size: 1.36rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.2rem;
        text-align: left;
      }

    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      flex: 1 0 0;
      width: 100%;

      span {
        color: $color-gray-bg;
        font-size: 1.2rem;
        font-family: Poppins-Medium, sans-serif;
        font-weight: 400;
        line-height: 1.8rem;
      }

      &--date {
        flex: 1 0 0;
        color: $color-gray-bg;
        font-family: Poppins, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      &--link-btn {
        flex: 1 0 0;
        width: 26.4rem;
        height: 2rem;
        color: $color-light-blue-secondary;
        text-align: right;
        font-family: Poppins, sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        text-decoration: none; 
        background: none; 
        border: none;
      }
    }
  }

  .notifiy-scroll .notifiy-popup-wrapper:last-child {
    border-bottom: none; /* Remove border-bottom for the last child */
  }
}
@include styles-for-device-size(sm) {
  .notify-popup-container {
   width:26.6rem;
   z-index:3;
   top:5rem; padding: 1.6rem 2.4rem;
   right:5.5rem; border-radius: 0.6rem;
   border: 0.1rem solid $color-light-gray-bg;
   background: $color-pure-white;
   box-shadow: 0.4rem 0 0.4rem 0.2rem $color-black-shadow;

   .notifiy-scroll {
    overflow-x: hidden;
    overflow-y: visible;
  }

   .notifiy-popup-wrapper{
    width: 21.2rem;

    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      flex: 1 0 0;
      width: 100%;
  
      span {
        color: $color-dark-gray-secondary;
        font-size: 1.2rem;
        font-family: Poppins-Medium, sans-serif;
        font-weight: 400;
        line-height: 1.8rem;
      }
    }   
   }

   .notifiy-scroll .notifiy-popup-wrapper:last-child {
    border-bottom: none; /* Remove border-bottom for the last child */
  }


   
  }
}
