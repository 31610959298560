@import "assets/styles/main";

.document-upload {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 6rem;
  margin: 1.6rem;
  box-shadow: 0 0.3rem 0.6rem #00000029;
  border-radius: 0.5rem;
  margin-left: 0;
  padding: 0 1rem;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.8rem 0 1.3rem;

    &--icon {
      font-size: 2rem;
      color: $color-primary;
    }

    &--file-name {
      margin-left: 0.8rem;
      color: $color-primary;
      min-width: 10rem;
      overflow-wrap: break-word;
      height: fit-content;
      font-size: 1.4rem;
      font-family: Poppins, sans-serif;
      text-align: left;
    }

    &--exclamation {
      display: none;
    }
  }

  &__file {
    display: flex;
    margin-right: 1.6rem;

    &--size {
      display: flex;
      color: $color-primary;
      font-size: 1.2rem;
      font-family: Roboto, sans-serif;
    }
  }

  &__status-bar {
    .status-bar {
      width: 24rem;
      border-radius: 6px;
      margin-left: 5rem;
      margin-right: 2rem;

      &__shaded-progress {
        background-color: $color-secondary;
      }
    }
  }

  &__cancel {
    margin-left: auto;

    &--check {
      font-size: 1.4rem;
      color: #288034;
    }

    &--delete {
      font-size: 1.4rem;
      color: $color-primary;
    }
  }

  &__failed {
    border: 0.1rem solid $color-danger;

    .document-upload__container {
      width: 18rem;
      display: flex;
      justify-content: flex-start;
      color: $color-danger;

      &--icon {
        color: $color-danger;
      }

      &--file-name {
        color: $color-danger;
      }
    }

    .document-upload__file {
      font-family: Roboto-Regular, sans-serif;

      &--size {
        color: $color-danger;
        font-size: 1.2rem;
      }

      &--status {
        color: $color-danger;
        margin-left: 0.7rem;
        font-size: 1.2rem;
      }

      &--message {
        color: $color-danger;
        margin-left: 12rem;
        font-size: 1.2rem;
      }
    }

    .document-upload__cancel svg {
      color: $color-danger;
    }
  }

  .document-delete-container {
    display: flex;
    gap: 16px;

    .document-delete-icon {
      cursor: pointer;
      font-size: 14px;
    }
  }
}

@include styles-for-device-size(xs) {
  .document-upload {
    flex-direction: column;
    align-items: flex-start;

    &__container {
      order: 1;
      margin-top: 1.6rem;

      &--icon {
        font-size: 2rem;
      }

      &--file-name {
        font-size: 1.4rem;
      }
    }

    &__file {
      order: 4;
      margin-top: 0.4rem;
      margin-left: 4rem;
      margin-bottom: 1.6rem;
    }

    &__status-bar {
      order: 3;

      .status-bar {
        margin-left: 4rem;
        width: 22rem;
      }
    }

    &__cancel {
      order: 2;

      &--check {
        font-size: 1.4rem;
        color: #288034;
      }

      &--delete {
        font-size: 1.4rem;
        color: $color-primary;
      }
    }

    &__failed {
      order: 5;

      .document-upload__container {
        width: auto;

        &--file-name {
          width: 20rem;
        }
      }

      .document-upload__file {
        margin-top: 0.8rem;
        margin-left: 1.2rem;

        &--size {
          font-size: 1.4rem;
          font-family: Roboto-Regular, sans-serif;
        }

        &--status {
          font-size: 1.4rem;
          font-family: Roboto-Regular, sans-serif;
        }
      }

      .document-upload__cancel {
        display: none;
      }

      .document-upload__container--exclamation {
        font-size: 1.2rem;
        display: inline;
        margin-left: 6rem;
        margin-bottom: 2.6rem;
      }
    }
  }
}