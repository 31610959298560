@import "assets/styles/main";

.header-menu {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__description {
    font-family: Poppins-Medium, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 22px;
    color: $color-white;
    white-space: nowrap;
  }

  &__icon {
    color: $color-off-white;
    margin-left: 1rem;
    font-size: 2rem;
  }
}