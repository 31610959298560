@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: $color-light-black-bg;

  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    margin: 1% auto;
    width: 52%;
    animation-name: modalopen;
    animation-duration: 1s;
  }

  &__footer h3 {
    margin: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-white;
    padding: 1.5rem;
    color: $color-primary;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    &--left {
      display: flex;
      align-items: center;
    }
  }

  &__body {
    padding: 1rem 2rem;
    background: $color-white;

    &--content {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
      text-align: center;

      &__label {
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 2rem;
        color: $color-primary;
        font-family: Poppins;
        font-weight: 600;
      }

      &__sub-content {
        font-size: 1.6rem;
        font-weight: $color-light-gray-bg;
        text-align: center;
        font-family: Roboto;
        width: 50%;
        height: 4.2rem;
      }
    }

    .custom-full-content {
      text-align: justify;
      font-family: Poppins, sans-serif;
      font-size: 1.6rem;
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    gap: 1.6rem;
    background: $color-white;
    padding: 2rem;
    color: $color-white;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    .btn {
      margin: 0;
    }
  }

  .close {
    color: $color-primary;
    font-size: 3rem;
  }

  .close:hover,
  .close:focus {
    cursor: pointer;
  }

  @keyframes modalopen {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .circle {
    width: 3.2rem;
    height: 3.2rem;
    padding: 0.3rem;
    font-size: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    &--primary {
      box-shadow: 0 0 0 2px $color-primary;
      background-color: $color-primary;
      color: $color-white;
    }
  }
}

@include styles-for-device-size(sm) {
  .modal {
    background: $color-white;

    &__content {
      width: 90%;
    }

    &__body {
      &--content {
        padding-top: 8rem;
        padding-bottom: 3rem;
        gap: 3rem;

        &__sub-content {
          width: fit-content;
          height: fit-content;
        }
      }
    }

    &__footer {
      flex-direction: column;
    }

    h3 {
      font-size: 1.6rem;
    }

    .circle {
      width: 2.2rem;
      height: 2.2rem;
      font-size: 1rem;
    }
  }
}
