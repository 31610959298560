.container {
  margin: 0 auto auto;
  display: flex;
  flex-direction: column;
  width: 90rem;
  justify-content: center;
  padding-top: 5.5rem;
}

@media (max-width: 1180px) {
  .container {
    width: 100%;
  }
}
