@import "assets/styles/main";

.multiple-choice {
  width: 100%;

  .form__wrapper {
    margin: 0;

    .input-container {
      margin-left: 1.75rem;
    }

    .smartCheckBox {
      margin-left: 0.75rem;

      .buttongroup {
        display: grid;
        grid-template-columns: repeat(3, 220px);
        column-gap: 1rem;
        margin-left: 2rem;
      }
    }

  }
}

.col {
  .form__wrapper {
    .input-container {
      margin-left: 0;
    }

    .mutlicheckbox {
      margin-left: 0;
    }
  }
}
