@import "assets/styles/main";

.declarations {
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  justify-content: left;
  width: 81rem;


  .no-declarations {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    align-items: center;
    background-color: $color-off-white;
    border: $color-light-gray-bg 0.1rem dashed;
    border-radius: 0.6rem;
    height: 10rem;

    p {
      font-family: Poppins, sans-serif;
      font-size: 1.4rem;
      font-weight: 400;
      color: $color-primary;
      line-height: 2rem;
    }

    img {
      width: 6rem;
      height: 6rem;
    }
  }

  .declaration-wrapper {
    justify-content: space-between;
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .declaration {
      justify-content: left;
      margin-right: 2rem;
      font-family: Poppins, sans-serif;
      font-size: 1.4rem;
      width: 27rem;
      text-align: left;
      color: $color-primary;
    }

    .declaration-value {
      margin-right: 2rem;
      font-family: Roboto, sans-serif;
      font-size: 1.4rem;
      width: 13rem;
      justify-self: left;
      text-align: left;
      color: $color-dark-black;
    }

    .declaration-edit {
      text-align: left;
      margin-right: 0;
      font-family: Poppins-SemiBold, sans-serif;
      font-size: 12px;
      color: $color-primary;
    }
  }
  @include styles-for-device-size(md) {
    width: 33rem;

    .declaration-wrapper {
       flex-wrap: wrap;
      
      .declaration {
        width: 24rem;
        margin-right: 1rem;
      }

      .declaration-value {
        width: 6.5rem;
        text-align: right;
        margin-right: 0;
      }
    }
  }
}
