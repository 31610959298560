@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.login {
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-style: normal;
  align-items: center;
  height: 100vh;

  .login-header {
    color: $color-dark-black;
    font-family: Poppins-Medium, sans-serif;
    font-size: 4.8rem;
  }

  .login-error {
    color: $color-danger;
    font: 1.6rem/2.1rem Roboto, sans-serif;
  }

  .login-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font: 1.6rem/2.1rem Roboto, sans-serif;
  }

  .forgot-password {
    margin: 2.2rem;
  }

  .sub-header {
    margin-bottom: 1.6rem;
  }

  .form-container {
    margin-top: 0.8rem;
  }

  .error__message {
    font-size: 1.8rem;
    font-family: Roboto, sans-serif;
    color: $color-danger;
    margin-bottom: 1.6rem;

    &_two {
      font-family: roboto-bold, sans-serif;
    }

    &_three {
      font-family: roboto-bold, sans-serif;
    }
  }

  .login-btn {
    width: 30.4rem;
    font-size: 1.6rem;
  }

  .consent__reader--wrapper {
    margin-top: 1.6rem;
    margin-bottom: 0;
  }

  ::-ms-reveal {
    display: none;
  }

  @include styles-for-device-size(lg) {
    .login-bottom {
      font-size: 1.4rem;
    }

    .login-header {
      font-size: 2.4rem;
      color: $color-primary;
    }

    .sub-header {
      margin-bottom: 2.4rem;
    }

    .error__message {
      font-size: 1.6rem;

      &_three {
        display: flex;
        flex-direction: column;
      }
    }

    .login-btn {
      width: 95%;
    }
  }

  .login-form {
    display: flex;
    width: 144rem;
    height: 102.4rem;
    flex-direction: column;
    align-items: center;
    margin: 1.6rem 0;

    &__content {
      text-align: left;
    }

    &__container {
      display: flex;
      width: 128rem;
      padding: 0 1.6rem;
      justify-content: space-between;
      align-items: flex-start;
      flex: 1 0 0;
    }

    &__header {
      color: $color-primary;
      font-family: Poppins, sans-serif;
      font-size: 3.2rem;
      font-style: normal;
      font-weight: 500;
      line-height: 2.4rem;
    }

    &__fields {
      display: flex;
      align-items: flex-start;
      margin-top: 2.4rem;

      .form__wrapper {
        width: 40rem;
      }

      .input-container {
        margin-bottom: 1.4rem;

        .input-label__wrapper label {
          margin-left: 0;
        }
      }

      .input {
        padding: 1.5rem 2.5rem;

        &__wrapper {
          margin-top: 0.8rem;
          margin-left: 0;
        }
      }
    }

    &__footer {
      width: 40rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .forgot-password {
        font-family: Roboto, sans-serif;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
      }

      span {
        font-family: Roboto, sans-serif;
        font-size: 1.4rem;
        text-align: center;
      }
    }

    &__image {
      position: absolute;
      left: 54%;
      height: 44.65rem;
      flex-shrink: 0;
      margin-top: .7rem;
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30.4rem;
      font: 1.6rem/2.1rem Roboto, sans-serif;
    }

    .error__message {
      font-size: 1.6rem;
      font-family: Roboto, sans-serif;
      color: $color-danger;
      margin-top: 1.6rem;
      line-height: 24px;
    }

    @include styles-for-device-size(lg) {
      width: 100%;
      align-items: baseline;
      margin: 2.7rem 0;

      &__image {
        position: static;
      }

      &__content {
        text-align: center;
        min-width: 34.4rem;
      }

      &__header {
        font-size: 2.4rem;
      }

      &__container {
        width: 100%;
        justify-content: center;
      }

      &__fields {
        align-items: normal;
        justify-content: center;

        .form {
          margin: 0;
          width: 100%;
        }

        .form__wrapper {
          width: 32.4rem;

          .input-label__wrapper label {
            margin-left: 1rem;
          }

          .input__wrapper {
            margin-left: 1rem;
          }
        }
      }

      &__footer {
        width: 100%;
      }

      .error__message {
        width: 34.3rem;
      }
    }
  }
}