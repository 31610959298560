@import "assets/styles/main";

.loans-section {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__wrapper {
        display: flex;
        flex-direction: column;
        width: 144rem;
        min-height: 95.2rem;
        padding: 0 8rem;
        margin: 4rem 1.6rem 0;
    }

    &__header {
        display: flex;
        align-items: center;
        color: $color-primary;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 2rem;
        gap: 0.8rem;

        &-icon-bg {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 1.6rem;
            background-color: $color-primary;
            width: 3.2rem;
            height: 3.2rem;

        }

        &-icon {
            color: $color-pure-white;
            width: 1.6rem;
            height: 1.6rem;
        }

        &-title {
            font-size: 2.4rem;
        }
    }

    &__card-container {
        display: flex;
        flex-wrap: wrap;
        padding: 4rem 0;
        row-gap: 1.6rem;
        column-gap: 1.6rem;
    }

    .footer--info {
        width: 144rem;
        padding: 2rem 8rem;
    }

    @include styles-for-device-size(xs) {
        &__wrapper {
            width: 100%;
            padding: 0 2rem;
            min-height: auto;
        }

        &__card-container {
            justify-content: center;
            padding: 4rem 0 2rem;
        }

        .footer--info {
            padding: 1.6rem 1.6rem 0;
        }
    }
}