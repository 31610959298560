@mixin styles-for-device-size($breakpoint) {
  $breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1440px,
  );

  @if map-has-key($breakpoints, $breakpoint) {
    @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

@mixin replace-btn-variant($bg-color, $color, $bg-hover-color, $color-hover) {
  color: $color;
  background-color: $bg-color;

  &:hover {
    color: $color-hover;
    background-color: $bg-hover-color;
  }
}
@mixin replace-btn-variant-no-hover(
  $bg-color,
  $color,
  $bg-hover-color,
  $color-hover
) {
  color: $color;
  background-color: $bg-color;
}
