@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.forgot-password {
  margin-top: 6.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 32%;
  }

  &__header {
    font-family: Poppins-medium, sans-serif;
    font-size: 4.8rem;
    color: $color-dark-black;
  }

  &__text {
    font-size: 1.8rem;
    font-family: Roboto-regular, sans-serif;
    color: #3b6ccf;
    margin-bottom: 1.6rem;
    line-height: 2.4rem;
  }

  .form {
    margin-top: 1.5rem;
    width: 32%;

    &__wrapper {
      width: 100%;

      .btn {
        margin-top: 1rem;
        width: 55%;
      }
    }
  }

  .error__container {
    margin-bottom: 1.7rem;
  }

  .error__message {
    font-size: 1.8rem;
    font-family: Roboto, sans-serif;
    color: $color-danger;
    width: 51.6rem;
    margin: auto;

    p:first-child {
      margin-top: 2.2rem;
    }

    p:last-child {
      margin-bottom: 3.2rem;
    }
  }

  @include styles-for-device-size(xs) {
    padding: 0 3.5rem;

    &__wrapper {
      padding: 0;
      width: 94%;
    }

    &__header {
      font-size: 2.4rem;
    }

    &__text {
      font-size: 1.6rem;
    }

    .form {
      margin-top: 2.4rem;
      width: 100%;

      &__wrapper {
        width: 100%;

        .input-label {
          margin: 0;
        }

        .input__wrapper {
          margin: 1rem 0;
        }

        .btn {
          margin: 0;
          width: 100%;
        }
      }
    }

    .error__container {
      margin-bottom: 0;
    }

    .error__message {
      font-size: 1.6rem;
      width: auto;
    }
  }
}