@import "assets/styles/main";

.loanCard {
  display: flex;
  width: 40.6rem;
  padding: 2.4rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.6rem;
  border: 0.1rem solid $color-light-gray-bg;
  background: $color-off-white;

  .loanCard-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__detail {
      font-size: 1.6rem;
      padding: 0.8rem 0;
      display: flex;
      align-items: center;
    }

    &__keyName {
      font-family: Poppins, sans-serif;
      font-weight: 600;
      color: $color-primary;
      margin-right: 0.5rem;
    }

    &__value {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      color: $color-dark-black;
    }

    &__detail:nth-child(2) {
      width: 34.8rem;

      .loanCard-wrapper__value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
      }
    }

    &__status {
      display: flex;
      align-self: flex-end;
      height: auto;
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 1.2rem;
      gap: 0.5rem;
      color: $color-pure-white;
      width: fit-content;
      border-radius: 2rem;
      padding: 0.4rem 0.8rem;
      line-height: 1.8rem;
    }

    .greeen {
      background-color: $color-success;
    }

    .blue {
      background-color: $color-secondary;
    }

    .red {
      background-color: $color-danger;
    }

    .grey {
      background-color: $color-gray-bg;
    }

    &__selectBtn {
      width: 100%;

      .btn {
        width: 85%;
      }
    }

    &__doc-notify {
      color: $color-primary;
      font-family: Poppins, sans-serif;
      font-size: 1.6rem;
      font-weight: 600;
      padding: 0.8rem 0;
      line-height: 2.2rem;
      min-height: 3.8rem;
    }
  }

  @include styles-for-device-size(xs) {
    width: 34.3rem;
    padding: 2.4rem 1.6rem;

    .loanCard-wrapper {
      &__detail {
        padding: 0.4rem 0;
      }

      &__detail:nth-child(2) {
        width: 100%;
      }

      &__selectBtn {
        .btn {
          width: 100%;
        }
      }

      &__doc-notify {
        padding: 0.4rem 0;
      }
    }
  }
}