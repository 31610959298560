@import "assets/styles/main";

.ssn-container {
  margin-bottom: 1.2rem;
  width: 30.4rem;

  .ssn {
    height: 5.2rem;
    border: 0.1rem solid $color-primary;
    border-radius: 0.5rem;
    font-family: Poppins;
    padding-left: 1rem;
    font-size: 1.6rem;

    &.ssn--error {
      border-color: $color-danger;
      border-radius: 0.5rem;

      &:focus {
        outline: none;
        border: 0.1rem solid $color-danger;
      }
    }

    &:focus {
      outline: none;
      border: 0.1rem solid $color-light-blue-secondary;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem 0;
      margin-left: 0;
      position: relative;
    }

    &--icon {
      position: absolute;
      right: 1.5rem;
      font-size: 1.5rem;
      top: 2rem;
      color: $color-secondary;

      &--pointer {
        cursor: pointer;
      }
    }
  }

  .ssn-label {
    margin-left: 0.1rem;
    font-family: Poppins-Medium;
    font-size: 1.4rem;
    color: $color-primary;

    &__wrapper {
      width: 100%;
      display: flex;
      align-items: baseline;
    }
  }

  ::-ms-reveal {
    display: none;
  }

  @include styles-for-device-size(sm) {
    width: 100%;
  }
}
