@import "assets/styles/abstracts/mixins";

.form {
  display: flex;
  flex-direction: column;

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: auto;
  }

  &__header {
    font-size: 1.3rem;
    display: block;
    height: 6rem;
  }

  @include styles-for-device-size(sm) {
    &__wrapper {
      // calculating width of form wrapper to line up with the consent div
      $consent-left-margin: 2.4rem;

      width: calc(100% - $consent-left-margin);
    }
  }
}

@media (max-width: 600px) {
  .form__wrapper {
    width: 100%;
  }

  .form {
    margin: 1rem 2rem;
  }
}
