@import "assets/styles/main";

.roundbadge {
  display: inline-block;
  position: relative;

  &--large {
    width: 5rem;
    height: 5rem;
    border-radius: 2.5rem;
  }

  &--small {
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
  }

  &--primary {
    background: $color-primary 0% 0% no-repeat padding-box;
  }

  &--secondary {
    background: $color-light-gray 0% 0% no-repeat padding-box;
  }

  &--value {
    font: normal normal 600 2.9rem/4.3rem Poppins;
    color: $color-white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
