@import "assets/styles/main";

.uqual-container {
    display: flex;
    width: 114.4rem;
    padding: 1.6rem 2.4rem;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: .6rem;
    border: .1rem solid $color-light-gray-bg;
    background: $color-off-white;
    box-shadow: 0 .4rem .4rem 0 #00000029;
    margin-top: 2rem;

    &__title {
        color: $color-dark-black;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2.4rem;
        align-self: stretch;
        width: 20rem;
        min-height: 4.8rem;
    }

    &__content {
        max-width: 79.6rem;
        min-height: 2.2rem;
        color: $color-dark-black;
        text-align: center;
        font-family: Poppins, sans-serif;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.2rem;
        align-self: center;
    }

    &__btn button {
        padding: .9rem 1.6rem;
        width: 10rem;
    }

    @include styles-for-device-size(sm) {
        display: flex;
        width: 34.3rem;
        padding: 1.6rem;
        flex-direction: column;
        align-items: flex-start;
        gap: .8rem;

        &__title {
            width: 100%;
            font-size: 1.6rem;
            line-height: 2.2rem;
            min-height: 2.2rem;
            text-align: left;
        }

        &__content {
            width: 100%;
            font-size: 1.4rem;
            line-height: 2rem;
            min-height: 4rem;
            text-align: left;
        }

        &__btn {
            align-self: center;

            button {
                width: 12rem;
            }
        }
    }
}