@import "assets/styles/main";

.textarea-container {
  margin-bottom: 1.4rem;
  display: flex;
  flex-direction: column;

  .textarea {
    height: 8rem;
    width: 100%;
    font-family: Poppins;
    padding: 1rem;
    resize: none;
    border-radius: 5px;

    &-label {
      font-family: Poppins-Medium;
      font-size: 1.4rem;
      color: $color-primary;
    }

    &.textarea--error {
      border-color: $color-danger;
    }

    &:focus {
      outline: none;
    }
  }
}

.textarea-wrapper {
  margin: 2rem 2rem 2rem 0;

  &--maxcharacter {
    float: right;
    color: $color-black;
    font-size: 1.6rem;
    font-family: Poppins-Medium;
  }
}