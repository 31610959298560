@import "assets/styles/main";

.file-details {
  display: flex;
  align-items: center;

  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    margin: 1.6rem 0 0 1.6rem;
    color: $color-secondary;
  }

  &__filename {
    font-family: Roboto-Medium, sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    color: $color-dark-black;
    margin: 1.8rem 0 0 0.8rem;
    width: 34rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;    
    white-space: nowrap;
  }

  @include styles-for-device-size(sm) {
    &__filename {
      word-wrap: break-word;
      margin-bottom: 0;
      font-size: 1.4rem;
      width: 27.4rem;
    }
  }
}

.file-upload {
  display: flex;
  margin-top: 0.8rem;
  justify-content: space-between;

  &__uploadedby {
    font-size: 1.4rem;
    font-family: Roboto-Medium, sans-serif;
    font-weight: 400;
    color: $color-light-black-dd;
    width: 23.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
  }

  &__createddate {
    font-size: 1.4rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: $color-secondary;
  }

  @include styles-for-device-size(sm) {
    flex-direction: column;
    align-items: baseline;

    &__createddate {
      margin-top: 0.5rem;
    }
  }
}
