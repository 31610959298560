@import "assets/styles/main";

.noLoanInfo {
    .noLoanContainer {
        padding-bottom: 4rem;

        .noLoanContent {
            border: .1rem solid $color-light-gray-bg;
            width: 83rem;
            height: 19.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: $color-off-white;
            gap: 2rem;
            border-radius: .6rem;
            color: $color-primary;

            .verbiage {
                font-family: Poppins-Medium, sans-serif;
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 2.2rem;
                text-align: left;
                width: 41.8rem;
                height: 6.6rem;
            }

            .propertyImage {
                background-size: cover;
                width: 17.2rem;
                height: 13.1rem;

                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    @include styles-for-device-size(sm) {
        .noLoanContainer {
            padding-bottom: 0;
            padding-top: 3.6rem;

            .noLoanContent {
                width: 34.2rem;
                height: 25.1rem;
                top: 19.0rem;
                left: 1.6rem;
                flex-direction: column;

                .verbiage {
                    text-align: center;
                    width: 29.4rem;
                    height: 8.8rem;
                }

                .propertyImage {
                    background-size: cover;
                    width: 13.2rem;
                    height: 10.1rem;
                }
            }
        }
    }
}