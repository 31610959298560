@import "react-toastify/dist/ReactToastify.css";
@import "assets/styles/main";

.Toastify__toast-icon {
  margin-inline-end: 16px;
}

.Toastify__toast {
  font-family: Poppins, sans-serif;
  font-size: 1.4rem;
  padding: 0;
  margin: 0;
  min-height: auto;
}

.Toastify__toast-body {
  margin: 0;
  padding: 8px 16px;
}

.Toastify__toast-container {
  width: auto;
}

.Toastify__toast-container--top-left {
  top: 0;
  left: 0;
}

.Toastify__toast-container--top-right {
  top: 14rem;
  right: 1rem;
}

.Toastify__toast-container--top-center {
  top: 10rem;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: $color-dark-black;
  background-color: #edf8ef;
  min-height: auto;
  border: $color-success;
  border-style: solid;
  border-width: thin;

  .Toastify__toast-icon {
    color: $color-success;
    margin-inline-end: 16px;
  }
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: $color-dark-black;
  background-color: $color-light-red-bg;
  min-height: auto;
  border: $color-danger;
  border-style: solid;
  border-width: thin;
  border-radius: 0.5rem;
  text-align: left;

  .Toastify__toast-icon {
    color: $color-danger;
    margin-right: 0.8rem;
    width: 1.6rem;
    height: 1.6rem;
  }

  .toast-container {
    .toast-header {
        font-weight: 500;
    }
    
    .toast-content {
        font-family: Roboto, sans-serif;
        font-weight: 400;
    }
  }
}

@include styles-for-device-size(md) {
  .Toastify__toast-theme--colored.Toastify__toast--success {
    margin-top: 5px;
    border-radius: 4px;
  }

  .Toastify__toast-container--bottom-center {
    bottom: 2rem;
    width: 80%;
    margin-left: 5.5rem;
    margin-right: 2rem;
  }
}
