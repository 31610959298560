@import "assets/styles/main";

.recover-password {
  margin: 0 auto auto;
  display: flex;
  width: 100rem;
  flex-direction: column;
  justify-content: center;
  padding: 6.4rem 0;

  &-header {
    &_title {
      font-size: 4.8rem;
      color: $color-dark-black;
      font-family: Poppins-Medium, sans-serif;
    }

    &_para {
      font-size: 1.8rem;
      color: $color-light-blue-secondary;
      font-family: Roboto, sans-serif;
    }

    &_error {
      font-size: 1.8rem;
      font-family: Roboto, sans-serif;
      color: $color-danger;
      margin-top: 3.2rem;
    }
  }

  &-form {
    margin-top: 0.6rem;
  }

  button {
    width: 30.4rem;
    margin-top: 1.2rem;
  }

  ::-ms-reveal {
    display: none;
  }

  @include styles-for-device-size(xs) {
    width: 100%;
    align-items: center;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      &_title {
        font-size: 2.4rem;
        color: $color-primary;
        font-family: Poppins-Medium, sans-serif;
      }

      &_para {
        font-size: 1.6rem;
      }

      &_error {
        margin-top: 2.4rem;
        font-size: 1.6rem;
        padding: 0 6rem;
      }
    }

    &-form {
      margin-top: 0.6rem;
    }

    button {
      margin-top: 0;
      width: 30.4rem;
    }
  }
}