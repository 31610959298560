@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.preApproval {
  z-index: 3;

  .modal__header {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-bottom: 0;
  }

  .model__content {
    width: 52%;
  }

  .modal__body {
    padding: 1rem 2rem;
  }

  .topSplitter {
    border: 0.1rem solid $color-light-gray-bg;
    margin-bottom: 2.4rem;
  }

  .sendIcon {
    padding-right: 0.5rem;
  }

  .previewFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
  }

  .previewFooter .sendPAL {
    order: 2;
    flex: 1 1 auto;
  }

  .previewFooter a {
    order: 1;
    flex: 1 1 auto;
  }

  @include styles-for-device-size(sm) {
    .model__content {
      width: auto;
    }

    .previewFooter {
      button {
        width: 100%;
      }

      .sendPAL {
        padding: 0.1rem 0.4rem;
        order: 1;
        flex: 1 1 auto;
      }

      a {
        order: 2;
        flex: 1 1 auto;
      }
    }

    .topSplitter {
      display: none;
    }
  }
}
