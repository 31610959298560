@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.pop-up {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #333333b3;
  animation: slide-in 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  &__content {
    display: flex;
    justify-content: center;
    margin: 1% auto;
    width: 81%;
    animation-name: dashboardpopup;
    animation-duration: 1s;
  }

  @keyframes dashboardpopup {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes slide-in {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }
}

@include styles-for-device-size(xs) {
  .pop-up {
    &__content {
      width: 100%;
    }

    &__content1 {
      margin-bottom: 0;
      margin-top: 6rem;
    }
  }
}
