@import "assets/styles/main";

.toc {
  display: flex;
  flex-direction: column;
  width: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__body {
      max-width: 49.1rem;
      display: flex;
      flex-direction: column;

      &--box {
        display: flex;
        color: $color-dark-black;
        font-family: Poppins-Medium, sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: 2.2rem;
      }
    }

    &__button {
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      &--btn1,
      &--btn2 {
        width: 30.4rem;
      }

      .active {
        background-color: $color-secondary;
      }
    }
  }
}

.toc-popup {
  .modal {
    display: flex;
    align-items: center;

    &__close-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }

    &__close-button {
      width: 1.6rem;
      height: 1.6rem;
      background: none;
      border: none;
      color: $color-primary;
      cursor: pointer;
    }

    .modal__content {
      width: 56rem;

      .modal__body {
        padding: 1rem 3.2rem;
      }

      .modal__header {
        padding: 3.2rem;
        padding-bottom: 2.2rem;
      }

      .modal__footer {
        display: flex;
        justify-content: center;
        padding: 0 2rem 1rem;
      }
    }

    .toc-container {
      &__body {
        &--checkbox {
          display: flex;
          align-items: center;
          margin-bottom: 3.2rem;

          .checkbox_wrapper {
            height: 2.4rem;

            input[type="checkbox"] {
              appearance: none;
              cursor: pointer;
              color: $color-gray-two;
              border: 0.1rem solid $color-gray-two;
              display: grid;
              place-content: center;
            }

            input[type="checkbox"]::before {
              content: "";
              width: 1.8rem;
              height: 1.8rem;
              transform: scale(0);
              transition: 50ms transform ease-in-out;
              box-shadow: inset 1.8rem 1.8rem $color-primary;
              border: 1px solid $color-gray-two;
            }

            input[type="checkbox"]:checked {
              border: 1.8px solid $color-secondary;
              background: #fff;
            }

            input[type="checkbox"]:checked::before {
              transform: scale(1);
            }

            margin: 0;

            input {
              width: 2.4rem;
              height: 2.4rem;
            }
          }

          &__text {
            color: $color-dark-black;
            font-family: Poppins, sans-serif;
            font-size: 1.8rem;
            font-weight: 500;
            line-height: 2.4rem;
          }
        }

        &--text1 {
          font-size: 1.6rem;
          font-family: Roboto, sans-serif;
          color: $color-primary;
          margin-bottom: 1rem;
          line-height: 2.2rem;
        }
      }

      &__button {
        padding: 2.2rem;
      }
    }
  }
}

.toc-error {
  color: $color-danger;
  font-size: 1.6rem;
  font-family: Poppins, sans-serif;
  margin-bottom: 2rem;
  display: flex;
  gap: 1rem;
}

.toc-container1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

@include styles-for-device-size(sm) {
  .toc-container1 {
    flex-direction: column;
  }

  .container {
    padding: 1.6rem;

    &__header {
      width: 34.3rem;
      font-size: 1.6rem;
    }

    &__body {
      margin-top: 0;

      &--text1 {
        font-size: 1.4rem;
        width: 31.4rem;
      }
    }

    &__button {
      margin-top: 0;
      padding: 1.4rem;
    }
  }

  .toc-popup .modal {
    align-items: flex-start;

    .modal__content .modal__body {
      padding: 7.4rem 2rem 0.2rem;
    }

    .toc-container {
      &__body {
        &--checkbox {
          margin-bottom: 2.4rem;

          .checkbox_wrapper {
            input {
              width: 1.8rem;
              height: 1.8rem;
            }

            input[type="checkbox"]::before {
              width: 1.4rem;
              height: 1.4rem;
              box-shadow: inset 1.5rem 1.5rem $color-primary;
              border: 1px solid $color-gray-two;
            }
          }

          &__text {
            font-size: 1.6rem;
          }
        }

        &--text1 {
          font-size: 1.4rem;
          margin: 0;
        }
      }
    }
  }

  .application-steps {
    padding: 0 1.6rem 1.6rem;
  }

  .voaInfo-wrapper {
    margin: 3.2rem -1.6rem 0;
  }

  .toc-error {
    margin: 0 -1.6rem 2rem;
  }
}
