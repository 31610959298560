@import "assets/styles/main";

.consent {
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4rem;
  background-color: $color-off-white;
  margin: 1rem 0;

  &__wrapper {
    max-width: 128rem;
    margin-bottom: 2.4rem;
  }

  &--item {
    color: $color-primary;
    padding: 2.4rem 2rem;
    text-align: left;
    line-height: 2.4rem;
    border-bottom: 0.1rem solid $color-light-gray-bg;
    margin-bottom: 1.6rem;

    a {
      color: $color-light-blue-secondary;
    }
  }

  &--reader {
    color: $color-primary;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
  }

  &__reader {
    display: flex;
    flex-direction: column;
    color: $color-gray;
    width: 100%;

    &--wrapper {
      display: flex;
      color: $color-white;
      padding: 1rem;
      margin-bottom: 1.6rem;
      background: $color-dark-black;
      height: 5.1rem;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}

@media (max-width: 1180px) {
  .consent__wrapper {
    width: auto;
  }

  .consent__reader--wrapper {
    height: auto;
  }
}

@include styles-for-device-size(xs) {
  .consent--item {
    padding: 2.4rem;
  }

  .consent--reader {
    padding: 0.5rem 2.4rem;
  }

  .consent__wrapper {
    width: 100%;
  }

  .consent__reader--wrapper {
    margin-top: 0.8rem;
    margin-bottom: 0;
    padding: 1.6rem 2rem;
    text-align: left;
  }
}