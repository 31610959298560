@import "assets/styles/main";

.dropdown-container {
  margin-bottom: 0.2rem;
  grid-row:4 / 5;
  grid-column: 1 / 2;
}

.input-label {
  font-family: Poppins-Medium, sans-serif;
  font-size: 1.4rem;
  color: $color-primary;
  text-wrap: nowrap;

  &__wrapper {
    width: 100%;
    display: flex;
    align-items: baseline;
  }
}

.dropdown__wrapper {
  margin: 10px;
  margin-left: 0;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.dropdown {
  padding: 1rem;
  border-radius: 5px;
  height: 5.2rem;
  border: 1px solid $color-gray-bg;
  background-color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: poppins, sans-serif;
  font-size: 1.6rem;
  color: $color-light-black-dd;
  cursor: pointer;

  &:focus {
    outline: none;
    border: 1px solid $color-light-blue-secondary;
  }

  &--error {
    border-color: $color-danger;
    border-radius: 5px;

    &:focus {
      outline: none;
      border: 1px solid $color-danger;
    }
  }
}

.state-select .dropdown{
  width: 12.3rem;
}

.dropdown-items {
  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 10px $color-light-gray-bg;
  border-radius: 5px;
  position: absolute;
  top: 115%;
  width: 100%;
  background-color: $color-white;
  z-index: 1;
  cursor: pointer;
  max-height: 10.7rem;
  overflow-y: auto;
  font-size: 1.4rem;
  color: $color-black-dd;
}

.select-item {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  font-family: poppins-Medium, sans-serif;
  background-color: #fefefe;
}

.select-item:hover {
  background-color: $color-off-white;
}

@include styles-for-device-size(sm) {
  .dropdown-icon {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  .dropdown-items {
    max-height: 20vh;
    z-index: 0;
  }

  .dropdown__wrapper {
    margin: 1rem 0;
  }
}
