@import "assets/styles/main";

.navbar {
  display: flex;
  justify-content: space-between;

  &__links {
    display: flex;
  }

  .link {
    font-size: 1.4rem;
    text-decoration: none;
    color: $color-white;
    padding: 2.3rem 3.1rem;
    font-family: Poppins-SemiBold, sans-serif;
    text-transform: uppercase;
  }

  .navbar-right {
    display: flex;

    .menu-link,
    .menu-link-splitter {
      font-size: 1.6rem;
      text-decoration: none;
      color: $color-white;
      padding-top: 2.3rem;
      padding-bottom: 2.3rem;
      font-family: Poppins-Medium, sans-serif;
      align-self: center;
      font-weight: 500;
      line-height: 22px;
    }

    .bell-icon {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1.6rem;
      align-self: center;

      .icon {
        font-size: 2.4rem;
      }
    }

    .menu-link-splitter {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  .link.active {
    border-top: 0.4rem solid $color-secondary;
    font-weight: 600;
  }

  .active {
    color: $color-primary;
    background-color: $color-white;
  }

  .hidden {
    visibility: hidden;
  }

  &__btn {
    color: $color-white;
    cursor: pointer;
    display: none;
  }
}

@include styles-for-device-size(sm) {
  .navbar {
    position: absolute;
    top: 5rem;
    left: 10%;
    min-height: 81rem;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-white;
    z-index: 3;
    animation: 1s slide-left;
    box-shadow: 0 0.1rem 0.3rem rgba(0 0 0 /30%);

    .link {
      display: flex;
      margin-left: 2.7rem;
      color: $color-primary;
      padding: 0;
      margin-bottom: 2.2rem;
      text-decoration: none;

      &__icon {
        font-size: 1.6rem;
        margin-right: 0.8rem;
      }

      &__text {
        font-size: 1.4rem;
      }
    }

    .link.active {
      border-top: none;
      font-weight: 0;
    }
    
    .lines {
      border-top: 0.1rem solid $color-light-gray-bg;
      margin-bottom: 2.1rem;
    }

    &__block {
      display: flex;
      gap: 2.4rem;
     }

    &__links {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 5.6rem;
    }

    &__btn {
      display: block;
    }

    &__close-btn {
      color: $color-primary;
      position: absolute;
      top: 2rem;
      left: 2rem;
      z-index: 3;
    }

    &--actions {
      transform: none;
    }

    @keyframes slide-left {
      from {
        margin-left: 100%;
      }

      to {
        margin-left: 0%;
      }
    }
  }
}

.pointer-on-hover :hover {
  cursor: pointer;
}