@import "assets/styles/main";

.popup {
  position: absolute;
  top: 7rem;
  right: 0;
  width: 29rem;
  height: 14rem;
  background: $color-white;
  border: 0 none;
  border-radius: 0.5rem;
  box-shadow: 0 0.1rem 0.3rem rgba(0 0 0 /30%);
  z-index: 2;

  &--show {
    display: block;
  }

  &--hide {
    display: none;
  }

  &__content {
    height: 63%;
    padding: 1rem;
    font-size: 1.2rem;
    color: $color-primary;
    text-align: left;
    font-family: poppins-Medium;
  }

  &__footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@include styles-for-device-size(sm) {
  .wrapper {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $color-gray-two;
  }

  .popup {
    top: 11.8rem;
    right: 1.6rem;
    height: 33rem;
    width: 92vw;
    padding: 0 1.9rem;

    &__close {
      font-size: 1.6rem;
      display: flex;
      justify-content: flex-end;
      margin-top: 1.6rem;

      .xmarkIcon{
        width: 1.4rem;
        height: 1.6rem;
      }
    }

    &__content {
      padding: 0;
      margin-top: 1.1rem;
      margin-bottom: 2.4rem;
      height: 30%;
    }

    &__text1 {
      font-size: 2.4rem;
      color: $color-primary;
      font-family: Poppins-Regular, sans-serif;
    }

    &__text2 {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      font-family: Roboto-Regular, sans-serif;
      color: $color-dark-black;
      line-height: 2rem;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      margin-bottom: 3.8rem;

      &--continue {
        width: 100%;
        margin-bottom: 1.6rem;
        height: 5.2rem;
        font-size: 1.6rem;
        font-family: Poppins-Medium, sans-serif;
      }

      &--logout {
        width: 100%;
        height: 5.2rem;
        font-size: 1.6rem;
        font-family: Poppins-Medium, sans-serif;
        border: 0.1rem solid $color-primary;
      }
    }
  }
}
