@import "assets/styles/main";
 
.credit-section {
  display: flex;
  flex-direction: column;
 
  .credit-applicant {
    padding: 1.6rem 0 3.2rem;
  }
 
  .details-wrapper {
    display: flex;
    margin-top: 1.6rem;
    gap: 1.6rem;
 
    .details-value {
      font-size: 1.6rem;
      color: $color-primary;
    }
    
    .segment-label {
      font-family: Poppins, sans-serif;
      font-size: 1.6rem;
      font-weight: 500;
      color: $color-primary;
      margin-bottom: 0.8rem;
      grid-column: 1 / span 2;
    }
 
    .date-of-birth,
    .social-security-number,
    .credit-rating
     {
      width: 30.5rem;
    }
 
    .social-security-number {
      display: grid;
 
      .edit-details-credit {
        justify-self: right;
        margin-right: -1.4rem;
        margin-top: -1.4rem;
 
        .edit-details {
          margin-top: 0;
        }
      }
    }
  }
 
  .credit-spouse-co-applicant {
    padding: 3.2rem 0;
    border-top: 0.1rem solid $color-light-gray-bg;
  }
 
  &__submit {
    margin-left: 0;
  }
 
  @include styles-for-device-size(md) {
    .details-wrapper {
      flex-direction: column;
 
      .date-of-birth,
      .social-security-number,
      .credit-rating {
        display: flex;
        flex-direction: column;
        width: 100%;
 
        .segment-label {
          font-size: 1.4rem;
          margin-bottom: 0;
          margin-right: 0.5rem;
          grid-column: auto;
        }
 
        .details-value {
          align-self: left;
          font-size: 1.4rem;
        }
      }
 
      .edit-details-credit {
        justify-self: left;
      }
    }
  }
}