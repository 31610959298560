@import "assets/styles/main";

.FundedState {
    display: inline-flex;
    flex-direction: column;
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 21rem;
    font-family: "Roboto Regular";
    color: $color-primary;

    &__congratulations {
        color: $color-light-blue-secondary;
        font-family: Poppins-semibold;
        font-size: 3.2rem;
        height: fit-content;
        word-wrap: break-word;
    }

    &__info1 {
        font-size: 1.8rem;
        height: fit-content;
        margin-top: 2rem;
    }

    &__info2 {
        width: 31.6rem;
        font-size: 1.8rem;
        align-self: center;
        height: fit-content;
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }

    button {
        color: $color-light-blue-primary;
        background-color: $color-primary;
        font-size: 1.6rem;
        width: 34.3rem;
        height: 5.2rem;
    }

    button:hover {
        background-color: $color-light-blue-secondary;
    }

    @include styles-for-device-size(sm) {
        margin-top: 4rem;
        margin-bottom: 9rem;
        width: 95%;
    }
}