@import "assets/styles/main";

.redirection-message-container {
    display: flex;
    justify-content: center;
    padding: 64px 80px;

    .content {
        max-width: 1460px;
        width: 90%;

        .redirection-message-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: $color-white;
            font-family: Roboto, sans-serif;
            font-size: 1.6rem;
            color: $color-primary;

            .nafHubLogo {
                height: 4.6rem;
                width: 18.4rem;
            }
        }

        .message-wrapper {
            display: flex;
            margin-top: 96px;

            .message {
                width: 50%;
                text-align: left;

                h1 {
                    font-size: 3.6rem;
                    font-weight: $font-weight-medium;
                    margin-bottom: 1.6rem;
                    color: $color-primary;
                }

                h2 {
                    font-size: 2.4rem;
                    font-weight: $font-weight-medium;
                    max-width: 58.5rem;
                    color: $color-primary
                }

                ul {
                    padding-left: 4rem;
                    margin: 2.4rem 0;

                    li {
                        font-size: 2.0rem;
                        font-family: Roboto, sans-serif;
                        font-weight: $font-weight-normal;
                        color: $color-dark-black;
                        margin-bottom: 1.6rem;
                    }
                }

                .btn {
                    height: 5.2rem;
                    width: 30.4rem;
                }

                .info-container {
                    display: flex;
                    gap: 1rem;
                    margin-top: 3.3rem;
                    align-items: baseline;

                    img {
                        position: relative;
                        top: 0.2rem;
                    }

                    p {
                        font-size: 1.6rem;
                        max-width: 54rem;
                        color: $color-dark-black;
                    }

                }
            }

            .image-container {
                width: 50%;
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    @include styles-for-device-size(sm) {
        padding: 4rem 1.6rem;

        .content {
            width: 100%;

            .redirection-message-header {
                .nafHubLogo {
                    height: 2.7rem;
                    width: 10.9rem;
                }
            }

            .message-wrapper {
                display: block;
                margin-top: 6.4rem;

                .message {
                    width: 100%;
                    max-width: 42rem;

                    .heading {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        h1 {
                            font-size: 2.4rem;
                            max-width: 36rem;
                            margin-bottom: 0.8rem;
                            text-align: center;
                        }
                    }

                    .sub-heading {
                        width: 100%;
                        display: flex;
                        justify-content: center;

                        h2 {
                            text-align: center;
                            font-size: 1.8rem;
                        }
                    }

                    ul {
                        li {
                            font-size: 1.8rem;
                        }
                    }

                    .btn {
                        margin-left: 4rem;
                    }

                    .info-container {
                        margin-top: 2.4rem;

                        p {
                            font-size: 1.4rem;
                        }
                    }

                }

                .image-container {
                    width: 100%;
                    margin-top: 2.4rem;

                    img {
                        width: 26.1rem;
                        height: 19.8rem;
                    }
                }
            }
        }
    }
}