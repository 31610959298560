@import "assets/styles/main";

.finances-section {
  .table-item {
    font-size: 1.6rem;
    font-family: Roboto, sans-serif;
  }

  .section-subheader {
    margin-bottom: 1.6rem;
  }

  .income {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .name {
      font-family: Roboto, sans-serif;
      font-size: 1.8rem;
      color: $color-dark-black;
      margin-bottom: 1.6rem;
      width: 40.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-details {
      display: flex;
      margin-bottom: 1.6rem;

      &__source {
        width: 40.5rem;
        font-family: Roboto, sans-serif;
        font-size: 1.6rem;
        color: $color-dark-black;
      }

      &__container {
        display: flex;
        flex: 1;
        text-align: left;
        align-items: center;
      }

      &__amount {
        font-size: 1.6rem;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: $color-dark-black;
        width: 21.6rem;
      }

      &__actions {
        flex: 2;
        text-align: left;
        margin-top: -1.4rem;
      }
    }

    .no-income {
      font-family: Roboto, sans-serif;
      font-size: 1.4rem;
      font-style: italic;
      color: $color-gray-bg;
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
    }

    .add-income-container {
      margin-top: 2rem;
    }
  }

  .assets {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;

    .category {
      font-family: Roboto, sans-serif;
      font-size: 1.8rem;
      color: $color-dark-black;
      margin-bottom: 1.6rem;
      width: 40.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

     .electronically-verified-assets-details-wrapper {
      margin-bottom: 3.2rem;
     }

    &-details {
      display: flex;
      margin-bottom: 1.6rem;

      &__source {
        width: 30rem;
        font-family: Roboto, sans-serif;
        font-size: 1.6rem;
        color: $color-dark-black;
      }

      &__type {
        width: 30rem;
        font-family: Roboto, sans-serif;
        font-size: 1.6rem;
        color: $color-dark-black;
      }

      &__container {
        display: flex;
        flex: 1;
        text-align: left;
        align-items: center;
      }

      &__amount {
        font-size: 1.6rem;
        font-family: Roboto, sans-serif;
        font-weight: 400;
        color: $color-dark-black;
        width: 30rem;
      }

      &__actions {
        flex: 2;
        text-align: left;
        margin-top: -1.4rem;
      }
    }

    .no-assets {
      font-family: Roboto, sans-serif;
      font-size: 1.4rem;
      font-style: italic;
      color: $color-gray-bg;
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
    }

    .add-assets-container {
      margin-top: 2rem;
    }
  }

  .section-border:not(:last-of-type) {
    border-bottom: 0.1rem solid $color-light-gray-bg;
  }

  @include styles-for-device-size(md) {
    .table-item {
      margin-bottom: 0;
    }

    .income {
      &-details {
        display: block;
        margin-bottom: 1.6rem;

        &__source {
          width: 50%;
          display: inline-block;
        }

        &__amount {
          width: 50%;
          display: inline-block;
          text-align: right;
        }

        &__actions {
          text-align: left;
        }
      }

      .name {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 1.8rem;
        width: 100%;
        color: $color-primary;
      }
    }

    .assets {
      .category {
        font-family: Poppins, sans-serif;
        font-weight: 500;
        font-size: 1.8rem;
        width: 100%;
        color: $color-primary;
      }
      
      .electronically-verified-assets-details-wrapper,
      .manually-listed-assets-details-wrapper {
        margin-bottom: 1rem;
           
        .assets-details {
          display: block;
          margin-bottom: 1.6rem;

          &__category {
            font-family: Roboto, sans-serif;

            &:first-of-type {
              margin-bottom: 1.6rem;
            }
          }

          &__source,
          &__amount {
            width: 50%;
            display: inline-block;
          }

          &__amount {
            text-align: right;
          }

          &__actions {
            text-align: left;
          }
        }
      }
    }
  }
}
