@import "assets/styles/main";

.modified-badge {
  font-family: Poppins;
  font-size: 1.2rem;
  height: 2.1rem;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.bg--new {
  background-color: $color-light-blue-secondary;
}

.bg--error {
  background-color: $color-danger;
}

.bg--submitted {
  background-color: $color-blue;
}

.bg--returned {
  background-color: $color-warning;
}

.bg--pending-approval {
  background-color: $color-black;
}



@include styles-for-device-size(sm) {
  .modified-badge {
    width: fit-content;
    height: 1.7rem;
    font-size: 1rem;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
  }
}