@import "assets/styles/abstracts/variables";
@import "assets/styles/main";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    font-size: 2.4rem;
    font-weight: 600;
    font-family: poppins, sans-serif;
    color: $color-black;
  }

  &__spinner-icon {
    max-width: 20rem;
    max-height: 20rem;
    animation: spin 1.25s linear infinite;
  }

  &__message1 {
    font-size: 1.8rem;
  }

  &__message2 {
    font-size: 1.6rem;
  }
}

@include styles-for-device-size(sm) {
  .loader {
    &__spinner-icon {
      max-width: 10rem;
      max-height: 10rem;
    }
  }
}
