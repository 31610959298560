@import "assets/styles/main";

.summaryContainer {
  display: flex;
  justify-content: center;

  .summaryWrapper {
    width: 96%;
    max-width: 128rem;

    .application-summary {
      text-align: left;
      width: 72.1rem;

      h1,
      h2,
      h3 {
        font-weight: 500;
        line-height: 1em;
      }

      &__header {
        margin-top: 3.2rem;

        .previous-question-button {
          background-color: inherit;
          border: none;
          color: $color-secondary;
          font-size: 1.6rem;
          font-family: Poppins, sans-serif;
          font-weight: 500;
          margin-bottom: 1.6rem;

          .arrow-left {
            margin-right: 0.8rem;
          }

          &:hover {
            cursor: pointer;
          }

          &.hidden {
            visibility: hidden;
          }
        }

        h2 {
          font-size: 3.6rem;
          margin-bottom: 1.6rem;
          color: $color-primary;
          font-family: Poppins, sans-serif;
          font-weight: 500;
        }

        p {
          font-size: 1.8rem;
          margin-bottom: 3.2rem;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          line-height: 2.4rem;
          width: 56rem;
          color: $color-dark-black;
        }
      }

      .summary-section {
        display: flex;

        .summary-section__name {
          font-size: 2rem;
          align-self: center;
          margin-left: 1.6rem;
        }
      }

      .summary-section-complete {
        padding: 3.2rem 0;
        border-bottom: 0.1rem solid $color-primary;
        width: 128rem;

        .complete-solid-check {
          font-size: 4rem;
          color: $color-secondary;
        }

        .summary-section__name-completed {
          font-size: 2.4rem;
          font-family: Poppins, sans-serif;
          font-weight: 500;
          color: $color-secondary;
          margin-left: 0.8rem;
          display: flex;
          align-items: center;
        }
      }

      .summary-section-current {
        .summary-section__name {
          font-size: 3.2rem;
          font-family: Poppins, sans-serif;
          font-weight: 500;
          color: $color-primary;
        }
      }

      .summary-section-upcoming {
        color: $color-inactive;
        padding: 3.2rem 0;
        border-bottom: 0.1rem solid $color-inactive;
        width: 128rem;
      }

      .current-section {
        width: 128rem;

        .summary-heading {
          display: flex;
          flex-direction: row;
        }

        .toast {
          align-self: center;
        }

        .Toastify__toast-container {
          position: relative;
          margin-left: 6rem;
          width: auto;
          height: 4rem;
          padding: 0;
        }

        padding: 3.2rem 0;
        border-bottom: 0.1rem solid $color-primary;

        .current-section__content {
          width: 128rem;

          .section-subheader {
            font-family: Poppins, sans-serif;
            font-weight: 600;
            font-size: 1.8rem;
            color: $color-primary;
          }

          .section-label {
            font-family: Poppins, sans-serif;
            font-size: 1.6rem;
            font-weight: 600;
            color: $color-primary;
            margin-bottom: 1.6rem;
          }

          .address__line1 {
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 1.6rem;
            color: $color-dark-black;
            white-space: nowrap;
            width: 40.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 0.8rem;
          }

          .tooltip-wrapper {
            position: absolute;
            display: flex;
          }

          .tooltip-wrapper .tooltip {
            visibility: hidden;
            height: 4.2rem;
            opacity: 0;
            transition: visibility 0s, opacity 0.2s linear;
            top: -7.5rem;
            margin: 0;
            background-color: $color-off-white;
            border-color: $color-light-gray-bg;
            color: $color-dark-black;
            border-radius: 1px;

            .tooltip--primary {
              width: max-content;
            }

            .tooltip--primary__info {
              padding-left: 0;
              font-family: Roboto-Regular, sans-serif;
            }

            .up::after {
              left: 1rem;
              border-top: 1.6rem solid $color-light-gray-bg;
              border-left: 1.05rem solid transparent;
              border-right: 1.05rem solid transparent;
            }

            .up::before {
              left: 1rem;
              border-top: 1.6rem solid $color-off-white;
              border-left: 1.05rem solid transparent;
              border-right: 1.05rem solid transparent;
            }
          }

          .address__line1:hover .tooltip-wrapper .tooltip {
            visibility: visible;
            opacity: 1;
          }

          .address__line2 {
            font-family: Roboto, sans-serif;
            font-size: 1.6rem;
            color: $color-dark-black;
          }

          .edit-details {
            font-family: Poppins, sans-serif;
            font-size: 1.6rem;
            font-weight: 600;
            color: $color-primary;
            margin: 1.6rem 1.6rem 0 0;

            &__icon {
              font-size: 1.8rem;
              margin-right: 0.8rem;
            }

            span {
              display: inline-block;
              transform: translateY(-25%);
            }
          }

          .remove-details {
            font-family: Poppins, sans-serif;
            font-size: 1.6rem;
            font-weight: 600;
            color: $color-remove;
          }

          .next-button {
            margin-left: 0;
            width: 30.4rem;
          }

          .add-button-container {
            display: flex;
            align-items: end;

            .add-button {
              display: flex;
              align-items: center;
              font-size: 1.6rem;
              font-family: Poppins-SemiBold, sans-serif;
              color: $color-primary;
              margin: 0;

              &__icon {
                margin-right: 0.8rem;
                height: 1.8rem;
                width: 1.8rem;
              }
            }
          }
        }
      }
    }
  }
}

@include styles-for-device-size(md) {
  .summaryContainer {
    .summaryWrapper {
      width: 100%;

      .application-summary {
        padding: 0 2rem;
        width: 100%;
        margin-left: 0;

        &__header {
          margin-top: 1.2rem;

          .previous-question-button {
            margin-left: auto;
            display: block;
          }

          h2 {
            font-size: 2.8rem;
            margin-bottom: 3.2rem;
            color: #091644;
          }

          p {
            font-size: 1.6rem;
            margin-bottom: 1.2rem;
            width: 93%;
          }
        }

        .summary-section {
          .summary-section__name {
            font-size: 1.8rem;
            align-self: center;
            margin-left: 1.6rem;
          }
        }

        .summary-section-current {
          .summary-section__name {
            font-size: 2.4rem;
          }

          .roundbadge {
            height: 4rem;
            width: 4rem;

            &--value {
              font-size: 2.4rem;
            }
          }
        }

        .current-section {
          width: 100%;

          .summary-heading {
            display: flex;
            flex-direction: column;
          }

          .toast {
            align-self: center;
            width: auto;
            height: auto;
            position: absolute;
            margin-top: 3.8rem;
          }

          .Toastify__toast-container {
            position: relative;
            margin: 0 2rem;
            width: auto;
            height: auto;
          }

          .current-section__content {
            margin-left: 0;
            width: inherit;

            .next-button{
              width: 72%;
            }

            .edit-details{
              font-size: 1.4rem;
            }

            .remove-details{
              font-size: 1.4rem;
            }

            .section-label{
              margin-bottom: 0.4rem;
              width: 90%;
            }

            .add-button-container {
              .add-button {
                transform: none;
                font-size: 1.2rem;
              }
            }

            .address__line1 {
              font-family: Roboto, sans-serif;
              font-size: 1.6rem;
              color: $color-primary;
              white-space: normal;
              width: 90%;
              margin-bottom: 0;
            }

            .address__line2 {
              margin-bottom: 0;
              width: 90%;
            }
          }
        }

        .summary-section-complete,
        .summary-section-upcoming {
          padding: 2.6rem 0;
          width: 100%;

          .roundbadge {
            height: 3rem;
            width: 3rem;

            &--value {
              font-size: 1.8rem;
            }
          }
        }

        .summary-section-complete {
          .complete-solid-check {
          font-size: 3rem;
        }

        .summary-section__name-completed {
          font-size: 1.8rem;

        }
        }
      }
    }
  }
}
