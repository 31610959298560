@import "assets/styles/main";

.acknowledgements-section {
  display: flex;
  flex-direction: column;
  width: 128rem;

  .acknowledgements-applicant {
    margin: 1.6rem 0;
  }

  .demographics {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.6rem;
  }

  &__submit {
    margin-top: 1rem;
    margin-left: 0;
  }

  .demographics-subheader {
    color: $color-primary;
    font-size: 1.8rem;
    font-family: Poppins, sans-serif;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .expandable-header {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.8rem;
    color: $color-secondary;
    font-family: Poppins, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
  }

  .details {
    padding-top: 1.6rem;
  }

  .details-label {
    font-family: Poppins-Medium, sans-serif;
    color: $color-primary;
    font-size: 1.6rem;
    text-align: left;
    margin-right: 0.5rem;
  }

  .details-value {
    font-family: Roboto, sans-serif;
    color: $color-dark-black;
    font-size: 1.6rem;
    text-align: left;
    overflow-wrap: break-word;
  }

  .edit-details {
    font-family: Poppins-SemiBold, sans-serif;
    font-size: 1.2rem;
    text-align: left;
    color: $color-primary;
  }

  hr {
    border: 0.1rem solid $color-light-gray-bg;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  @include styles-for-device-size(md) {
    width: 100%;

    .declarations {
      width: 33rem;
    }
  }
}
