@import "assets/styles/main";

.dropDownMenu {
  .popup {
    position: absolute;
    top: 7rem;
    right: 0;
    width: 31.2rem;
    height: auto;
    background: $color-white;
    border: 0 none;
    border-radius: 0.5rem;
    box-shadow: 0 0.1rem 0.3rem rgba(0 0 0 /30%);
    z-index: 2;

    &--show {
      display: block;
    }

    &--hide {
      display: none;
    }

    &__wrapper {
      margin: 2.2rem;
      text-align: left;

      &--content {
        font-size: 1.4rem;
        color: $color-primary;
        text-align: left;
        font-family: Poppins-Medium, sans-serif;
      }

      &--line {
        margin-top: 1.2rem;
        border-bottom: 1px solid $color-light-gray-bg;
      }

      &__top-buttons {
        display: flex;
        color: $color-primary;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 2.2rem;
        margin-top: 1.4rem;
        font-family: Poppins-Medium, sans-serif;
        justify-content: end;
      }

      &__buttons {
        color: $color-primary;
        font-weight: 500;
        font-size: 1.6rem;
        margin-top: 1.4rem;
        font-family: Poppins-Medium, sans-serif;

        &__preferences {
          cursor: pointer;
        }

        &__logout {
          width: 7.9rem;
          font-family: Poppins-Medium, sans-serif;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 1.4rem;
          cursor: pointer;
        }
      }
    }

    @include styles-for-device-size(sm) {
      box-shadow: none;
      top: 2rem;
      width: 100%;
      margin-top: 1.6rem;

      &__wrapper {
        text-align: end;

        &--content {
          text-wrap: nowrap;
          text-align: end;
        }

        &__buttons__logout {
          margin-left: 73%;
        }
      }
    }
  }
}
