@import "assets/styles/abstracts/variables";
@import "assets/styles/main";

.icon-basic,
%icon-dimensions {
  width: 1rem;
  height: 1rem;
}

.password-checkerv2 {
  font-size: 1.4rem;
  padding: 1.6rem;
  font-family: Poppins, sans-serif;
  background: $color-pure-white;
  font-weight: 400;
  box-shadow: 0 0.4rem 0.4rem $color-black-shadow;
  width: 40rem;
  text-align: left;
  float: left;
  border-radius: 5px;
  margin: 1rem;

  ul {
    font-family: Roboto, sans-serif;
    line-height: 2rem;
  }

  li {
    margin-bottom: 1.6rem;

    span {
      margin-left: .8rem;
    }
  }

  li:nth-child(1) {
    margin-top: 1.6rem;
  }

  li:nth-child(5) {
    margin-bottom: 0;
  }

  .valid span {
    color: $color-success;
  }

  .invalid span {
    color: $color-danger;
  }

  &--validTrue {
    display: block;
  }

  &--validFalse {
    display: none;
  }

  .valid,
  .invalid {
    text-align: left;
    font-size: 1.4rem;

    span {
      opacity: 1;
    }
  }

  .invalidMark {
    @extend %icon-dimensions;

    fill: $color-danger;
    width: 1.3rem;
    height: 1.4rem
  }

  .validCheck {
    @extend %icon-dimensions;

    fill: $color-success;
    width: 1.3rem;
    height: 1.4rem
  }

  @include styles-for-device-size(xs) {
    width: 30.4rem;
  }
}

.password-checker {
  text-align: left;
  float: left;
  font-size: 1.3rem;
  padding: 1rem;
  background: $color-off-white;
  font-weight: 600;
  border-radius: 5px;
  margin: 1rem;

  &--validTrue {
    display: block;
  }

  &--validFalse {
    display: none;
  }

  .valid,
  .invalid {
    text-align: left;
    font-size: 1.2rem;

    span {
      opacity: 1;
    }
  }

  .invalidCircle {
    @extend %icon-dimensions;

    fill: $color-dark-gray-secondary;
  }

  .invalidMark {
    @extend %icon-dimensions;

    fill: $color-danger;
    width: 1.2rem;
    height: 1.4rem
  }

  .validCheck {
    @extend %icon-dimensions;

    fill: $color-success;
    width: 1.3rem;
    height: 1.4rem
  }
}