@import "assets/styles/main";

.documents-container {
  margin: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 128rem;

  &__line {
    width: 100%;
    border-bottom: 0.1rem solid $color-light-gray-bg;
  }

  .modal {
    &__content {
      max-width: 86.8rem;
      width: auto;
    }

    &__header {
      border-radius: 1rem 1rem 0 0;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: end;

    &--content {
      &--text {
        display: flex;
        font-size: 1.4rem;
        font-family: Roboto, sans-serif;
      }

      &--sub-text {
        color: $color-secondary;
      }
    }

    &--icon {
      display: flex;
      font-size: 2rem;
      align-items: center;
      gap: 1.2rem;

      &--text {
        font-family: Poppins-SemiBold, sans-serif;
        font-size: 2.4rem;
        color: $color-primary;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: center;
    margin-bottom: 3.2rem;

    &--text2 {
      font-size: 1.6rem;
      font-family: Roboto-Regular, sans-serif;
      color: $color-gray-bg;
      width: 66.3rem;
      line-height: 3rem;
    }
  }

  &__title {
    font-size: 1.8rem;
    font-weight: 500;
    font-family: Poppins-Medium, sans-serif;
    color: $color-primary;
    margin-top: 1.6rem;
    text-align: left;
  }

  &__file-upload {
    .btn {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      gap: 0.7rem;
      font-size: 1.4rem;
      font-family: Poppins-Medium, sans-serif;
      width: 22rem;
      height: 4.4rem;
      cursor: pointer;
      margin-top: 1.6rem;
    }
  }

  &__sub-text {
    color: $color-black;
    margin-top: 2rem;
    font-size: 1.8rem;
    font-family: Poppins-SemiBold, sans-serif;
    text-align: left;
  }

  &__cards-active {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 3rem;

    &:last-child {
      margin-bottom: 2.4rem;
    }
  }

  &__card {
    cursor: pointer;
    width: 40.5rem;
  }

  .documents {
    &__placeholder {
      color: $color-gray-bg;
      font-family: Poppins, sans-serif;
      font-size: 1.6rem;
      padding: 1.6rem 1.6rem 2.4rem 0;
      text-align: left;
    }
  }

  .card {
    animation: none;
    margin-top: 1.6rem;
    border: 0.1rem solid $color-light-gray-bg;
    box-shadow: none;
    height: 9.2rem;

    &__header {
      margin-left: 0;
      display: none;
    }

    &__body {
      height: 4.8rem;
    }

    &__footer {
      margin: 0 1.5rem 0 1.6rem;
    }
  }

  .modal__body {
    padding: 2rem 7rem;
  }

  .success-message__container {
    .modal {
      &__content {
        margin-top: 10rem;
      }

      &__header {
        display: none;
      }

      &__body {
        border-radius: 0.5rem;
        height: 42.7rem;
        max-width: 86.7rem;
        padding: 13rem;
        text-align: center;

        .message {
          &__primary {
            font-size: 2.4rem;
            color: $color-primary;
            font-family: Poppins-Medium, sans-serif;
            margin-bottom: 2.4rem;
          }

          &__secondary {
            font-size: 1.8rem;
            font-family: Roboto, sans-serif;
            margin-bottom: 3.2rem;
          }
        }
      }
    }
  }
}

.documents-container .modal__body .modal:first-child {
  background-color: transparent;
}

#header-bar,
#pdf-download {
  display: none;
}

#pdf-controls {
  box-shadow: none;
}

#pdf-page-wrapper {
  margin: 0;
}

#react-doc-viewer {
  border: 0.1rem solid $color-light-gray-bg;
}

.react-pdf__Page {
  max-height: 55rem;
}

@include styles-for-device-size(sm) {
  .documents-container {
    padding: 4rem 0 0;
    width: 100%;

    &__card {
      width: 100%;
    }

    &__cards-active {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: space-between;
      grid-column-gap: 1rem;
      margin-bottom: 1.6rem;
    }

    &__cards {
      display: none;
    }

    .documents {
      &__placeholder {
        display: none;
      }
    }

    .card {
      width: 100% !important;
      border-radius: 0.5rem;
      min-height: 11.2rem;
    }

    &__count,
    &__count-active {
      margin-top: 0.8rem;
      font-size: 1.4rem;
      color: $color-gray-bg;
      font-family: Roboto, sans-serif;
      text-align: left;
    }

    &__count {
      margin-bottom: 1.6rem;
    }

    &__title_wrapper {
      display: flex;
      justify-content: space-between;

      .chevron-arrow {
        padding-top: 1.6rem;
        height: 1.4rem;
        width: 1.4rem;
        font-size: 1.4rem;
      }
    }

    &__title {
      padding: 1.6rem 0 0;
      text-align: left;
      margin-top: 0;
    }

    &__content {
      margin-left: 0;
      margin-top: 2.4rem;
      text-align: center;

      &--text2 {
        width: 30rem;
        font-size: 1.4rem;
        line-height: 2rem;
      }
    }

    &__file-upload {
      .btn {
        width: 88%;
        justify-content: center;
        font-size: 1.6rem;
        height: 5.2rem;
        margin-top: 0;
        margin-bottom: 2.4rem;
      }
    }

    &__line {
      width: 100vw;
      position: absolute;
      left: 0;
    }

    &__sub-text {
      margin-top: 3.4rem;
      margin-bottom: 1.6rem;
    }

    .success-message__container {
      .modal {
        background-color: $color-white;
        display: block;

        &__content {
          width: 34.3rem;
          margin-top: 0;
        }

        h3 {
          font-size: 2.4rem;
        }

        &__header {
          display: flex;
          margin-top: 2rem;
        }

        .close {
          font-size: 2rem;
          margin-top: -2rem;
        }

        &__body {
          max-width: 34.3rem;
          height: fit-content;
          min-width: 0;
          padding: 0;
          text-align: center;
          margin-top: 4.9rem;

          .message {
            &__primary {
              font-size: 2.4rem;
              color: $color-primary;
              font-family: Poppins-Medium, sans-serif;
              margin-bottom: 2.4rem;
            }

            &__secondary {
              font-size: 1.6rem;
              font-family: Roboto, sans-serif;
              margin-bottom: 2.4rem;
            }
          }
        }
      }
    }
  }

  .react-pdf__Page {
    min-height: 45rem;
  }
}
