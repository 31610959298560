@import "assets/styles/main";

.table {
  width: 100%;
  position: relative;
  padding-bottom: 3.4rem;

  .modal {
    &__content {
      width: 86.7rem;
    }

    &__header {
      border-radius: 1rem 1rem 0 0;
    }

    &__body {
      padding: 1rem 8rem;
    }
  }

  .modal:first-child {
    background-color: transparent;
  }
}

.table-items,
.table-content {
  border-top: 0.1rem solid $color-light-gray-bg;
  font-family: Poppins-Medium, sans-serif;
  font-size: 1.6rem;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 2rem 0 1.7rem;
  overflow-y: scroll;
  padding: 0.8rem 0.6rem 0 0;

  &__highlight {
    z-index: 2;
    position: absolute;
    width: 100%;
    border-radius: 0.5rem;
    left: 0;
    padding-left: 1.2rem;
    padding-right: 3.2rem;
    border: 0.5rem solid $color-light-blue-secondary;
  }

  &__container {
    display: flex;
    width: 91%;
  }

  &__titles,
  &__titles--max_width {
    text-align: left;
    margin-left: 2.1rem;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
    color: $color-light-black;
  }

  &__titles {
    width: 75%;
  }

  &__status {
    font-size: 1.2rem;
    margin-top: 2.1rem;
    margin-bottom: 2.3rem;
    margin-left: 7%;

    &__tooltip--show {
      button {
        cursor: auto;
      }
    }
  }

  &__action {
    color: $color-primary;
    cursor: pointer;

    &__tooltip--show {
      cursor: auto;
    }
  }

  .tooltip-popup {
    position: absolute;
    z-index: 2;
    left: 40rem;
    top: 8rem;
  }

  &__main,
  .item {
    display: flex;
    align-items: center;
    height: 6.1rem;

    .success-message__container {
      .modal {
        &__content {
          margin-top: 10rem;
        }

        &__header {
          display: none;
        }

        &__body {
          border-radius: 0.5rem;
          height: 42.7rem;
          min-width: 86.7rem;
          padding: 13rem;
          text-align: center;

          .message {
            &__primary {
              font-size: 2.4rem;
              color: $color-primary;
              font-family: Poppins-Medium, sans-serif;
              margin-bottom: 2.4rem;
            }

            &__secondary {
              font-size: 1.8rem;
              font-family: Roboto, sans-serif;
              margin-bottom: 3.2rem;
              color: $color-dark-black;
            }
          }

          button {
            color: $color-light-blue-primary;
          }
        }
      }
    }
  }

  .item .success-message__container .modal {
    background-color: transparent;
  }

  .item:last-child .success-message__container .modal {
    background-color: $color-light-black-bg;
  }

  &__main:nth-child(odd) {
    background-color: #f5f8ff;
  }
}

.table-items {
  max-height: 31.15rem;

  &::-webkit-scrollbar {
    width: 0.9rem;
  }

  &::-webkit-scrollbar-track {
    background-color: $color-light-gray-bg;
    border-radius: 1rem;
    margin: 0.8rem 0 0.1rem;
  }

  &::-webkit-scrollbar-thumb {
    height: 17rem;
    background: $color-gray-bg;
    border-radius: 1rem;
  }
}

.table-content {
  .table-items__main,
  .item {
    margin-bottom: 2.4rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.table:has(+ .naf-insurance) .table-items,
.table-content {
  border-bottom: 0.1rem solid $color-light-gray-bg;
}

@include styles-for-device-size(sm) {
  .table-items {
    border-top: none;
    font-family: Poppins-Medium, sans-serif;
    height: 41.2rem;
    font-size: 1.4rem;
    width: 90%;
    margin-left: 2rem;
    margin-right: 1rem;
    padding-right: 0.6rem;

    &__container {
      display: flex;
      flex-direction: column;
      align-items: start;
    }

    &__highlight {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }

    &__titles,
    &__titles--max_width {
      font-size: 1.4rem;
      width: 80%;
      height: fit-content;
    }

    &__status {
      margin-top: 1.1rem;
      margin-left: 2rem;
      margin-bottom: 1.1rem;
    }

    &__action {
      margin-top: 2rem;
      margin-right: 1.6rem;
    }

    .tooltip-popup {
      left: 1.5rem;
      top: 54%;
    }

    .modal {
      &__content {
        width: 100%;
      }

      &__body {
        padding: 1rem 4rem;
      }
    }

    &__main {
      align-items: baseline;
      height: fit-content;

      .success-message__container {
        .modal {
          background-color: $color-white;
          display: block;

          &__content {
            width: 34.3rem;
            margin-top: 0;
          }

          h3 {
            font-size: 2.4rem;
          }

          &__header {
            display: flex;
            margin-top: 2rem;
          }

          .close {
            font-size: 2rem;
            margin-top: -2rem;
          }

          &__body {
            max-width: 34.3rem;
            height: fit-content;
            min-width: 0;
            padding: 0;
            text-align: center;
            margin-top: 4.9rem;

            .message {
              &__primary {
                font-size: 2.4rem;
                color: $color-primary;
                font-family: Poppins-Medium, sans-serif;
                margin-bottom: 2.4rem;
              }

              &__secondary {
                font-size: 1.6rem;
                font-family: Roboto, sans-serif;
                margin-bottom: 2.4rem;
              }
            }
          }
        }
      }
    }
  }

  .table-content {
    padding-right: 0;
  }
}

.transparent-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-transparent-gray;
  z-index: 1;
}
