@import "assets/styles/abstracts/variables";

.error-message__container {
  color: $color-danger;
  text-align: left;
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-family: Poppins, sans-serif;
  display: flex;

  .error-message {
    &__icon {
      margin-right: 0.6rem;
    }
  }
}

.dropdown__wrapper .error-message__container:nth-of-type(2) {
  width: 14.2rem;
}

.single-input .form__wrapper .dropdown__wrapper .error-message__container:nth-of-type(2) {
  width: 100%;
}