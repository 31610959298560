@import "assets/styles/main";

.guide-tooltip-arrow {
    position: relative;
    width: fit-content;

    &::before {
        content: "";
        position: absolute;
        top: -1.4rem;
        left: 50%;
        transform: translateX(-50%);
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 2rem solid $color-light-blue-secondary;
    }

    .guide-tooltip-container {
        background-color: $color-pure-white;
        border: 0.2rem solid $color-light-blue-secondary;
        width: 31.6rem;
        max-height: 18.8rem;
        position: relative;
        text-align: left;
    
        .guide-tooltip-icon {
            cursor: pointer;
            color: $color-pure-white;
            position: absolute;
            top: 0.8rem;
            left: 28.5rem;
            height: 1.4rem;
            width: 1.1rem;
        }
    
        .guide-tooltip-top-layer {
            background-color: $color-light-blue-secondary;

            .guide-tooltip-heading {
                color: $color-pure-white;
                padding-top: 0.5rem;
                padding-left: 1.6rem;
                padding-bottom: 0.5rem;
                font-size: 1.4rem;
                font-weight: $font-weight-medium;
                line-height: 2rem;
            }
        }
    
        .guide-tooltip-bottom-layer {
            padding-left: 1.6rem;
            padding-right: 1rem;
            background-color: $color-pure-white;
            color: $color-dark-black;
            font-family: roboto,sans-serif;
    
            .guide-tooltip-number {
                padding-top: 1rem;
                font-size: 1.4rem;
                font-weight: $font-weight-normal;
            }
    
            .guide-tooltip-content {
                padding-top: 1rem;
                font-size: 1.4rem;
                font-weight: $font-weight-normal;
            }
    
            .guide-tooltip-button {
                margin-top: 2rem;
                margin-bottom: 2rem;
    
                button {
                    font-family: poppins,sans-serif;
                    font-size: 1.4rem;
                    color: $color-pure-white;
                    background-color: $color-primary;
                    border-radius: 0.5rem;
                    border: 0;
                    padding: 1rem;
                    cursor: pointer;
                }

                .tooltip3-button-style{
                    margin-top: 2rem;
                }
            }
        }
    }
}