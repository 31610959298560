@import "assets/styles/main";

$header-left-padding: 14.5rem;

.header-container,
.new-header {
  display: flex;
  background-color: $color-primary;
  justify-content: center;

  .nafhub-header,
  .document-header {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    min-height: 8rem;
    font-family: Roboto, sans-serif;
    position: relative;

    .nafhub-logo {
      height: 2.8rem;
    }

    .nafhub-phonenumber {
      color: $color-white;
      font-size: 1.8rem;
      font-family: Roboto, sans-serif;
    }

    .myprogress {
      color: white;
      position: absolute;
      bottom: 7%;
      font-size: 1.4rem;
      font-family: Poppins, sans-serif;
    }

    .hide-links {
      width: auto;
    }

    .show-links {
      width: 78%;
    }

    @include styles-for-device-size(sm) {
      padding: 0 1.5rem;
      height: 5.4rem;

      .show-links {
        width: auto;
      }
    }
  }

  .document-header {
    width: 128rem;
  }

  .nafhub-header {
    width: 115rem;
  }
}

.new-header {
  background-color: $color-pure-white;

  .nafhub-header {
    width: 144rem;
    padding: 6.4rem 9.6rem;
    min-height: 4.6rem;
    align-items: normal;

    .nafhub-logo-v2 {
      width: 18.4rem;
      height: 4.6rem;
    }

    .nafhub-phonenumber {
      color: $color-primary;
      font-size: 1.6rem;
      align-self: flex-start;
    }

    @include styles-for-device-size(sm) {
      width: 100%;
      padding: 0 1.6rem;
      margin-top: 4rem;

      .nafhub-logo-v2 {
        width: 10.9rem;
        height: 2.7rem;
      }

      .nafhub-phonenumber {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}

@include styles-for-device-size(sm) {
  .header-container {
    .nafhub-header {
      width: 100%;
    }
  }
}

@include styles-for-device-size(xs) {
  .header-container {
    .nafhub-header,
    .document-header {
      min-height: 6.4rem;
      width: 100%;

      .nafhub-logo {
        height: 2.5rem;
      }

      .nafhub-phonenumber {
        font-size: 2rem;
      }
    }
  }
}
