@import "assets/styles/main";

.expandable-header {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 0.8rem;
    color: $color-secondary;
    font-family: Poppins, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
  }