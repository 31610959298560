@import "assets/styles/main";

.data {
  font-size: 1.8rem;
  margin-bottom: 1.6rem;

  &__label {
    font-family: Roboto, sans-serif;
    color: $color-primary;
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
  }

  &__values {
    font-family: Roboto-Medium, sans-serif;
    margin-bottom: 1.6rem;
    color: $color-dark-black;
  }

  &__table{
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $color-dark-black;
      font-family: Roboto-Medium, sans-serif;
      font-size: 1.8rem;
      margin-bottom: 0.8rem;
      width: 29.3rem;
    }
  }

  ul {
    margin-left: 2.4rem;
    font-size: 1.6rem;
    font-family: Roboto, sans-serif;

    li {
      padding: 1.6rem;
      line-height: 2.2rem;
    }
  }

@include styles-for-device-size(xs) {
    font-size: 1.6rem;

    &__label {
      font-size: 1.4rem;
    }

    &__table {
      &__row {
        font-size: 1.7rem;
      }
    }
  
}
}