@import "assets/styles/main";

.disclaimer {
  width: 87rem;
  height: fit-content;
  margin-top: 15rem;

  .disclaimer--header {
    display: flex;
    align-items: center;

    &__icon {
      color: $color-light-blue-secondary;
      font-size: 1.4rem;
      align-self: flex-start;
      margin: 0.3rem 0.8rem 0.4rem 0;
    }

    &__label {
      font-size: 1.6rem;
      font-family: Poppins, sans-serif;
      color: $color-primary;
    }
  }

  .disclaimer--text {
    width: 100%;
    height: fit-content;
    padding: 1.6rem;
    background-color: #f8f9ff;
    color: $color-light-black;
    font-size: 1.2rem;
    text-align: left;
    font-family: Roboto, sans-serif;
  }

  @include styles-for-device-size(sm) {
    width: 100%;
    height: fit-content;
    margin-top: 6.4rem;

    .disclaimer--header {
      &__label {
        font-size: 1.4rem;
        width: fit-content;
      }
    }
    
    .disclaimer--text {
      width: 100%;
    }
  }
}
