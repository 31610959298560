@import "assets/styles/abstracts/variables";

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
  background-color: black;
  z-index: 100;
}

.loading-overlay-block {
  margin: auto;
  display: block;
  shape-rendering: auto;
  width: 20rem;
  height: 20rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;

  &__text {
    font-size: 2rem;
    text-align: center;
    color: $color-white;
  }
}
