@import "assets/styles/main";

.checkbox_wrapper {
  height: 2.7rem;
  margin-bottom: 0.95rem;
  display: flex;
  align-items: center;
  grid-column: 1 / span 2;
}

.hide {
  display: none;
}

.checkbox_wrapper label {
  font-family: Poppins-Medium, sans-serif;
  font-size: 1.4rem;
  color: $color-primary;
  margin-left: 0.8rem;
  cursor: pointer;
}

.checkbox_wrapper input {
  accent-color: $color-secondary;
}

.checkbox_wrapper.disabled {
  pointer-events: none;

  input {
    color: $color-inactive;
    opacity: 0.5;
  }
}
