@import "assets/styles/main";

.pal-popup {
    width: 61.6rem;
    height: 39.8rem;
    display: flex;
    flex-direction: column;
    padding: 3.2rem;
    border-radius: 0.6rem;
    background-color: $color-pure-white;
    align-items: center;
    gap:2.4rem;

    &__close-button {
        width: 1.6rem;
        height: 1.6rem;
        align-self: flex-end;
        background: none;
        border: none;
        color: $color-primary;
        cursor: pointer;
    }

    &__animation {
        img {
            width: 10rem;
            height: 10rem;
        }
    }

    &__title {
        font-family: Poppins, sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 3rem;
        color: $color-primary;
    }

    &__message {
        font-family: Roboto, sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 2.4rem;
        color: $color-dark-black;
    }

    &__action-button {
        button {
            width: 30.4rem;
        }
    }

    @include styles-for-device-size(xs) {
        width: 37.5rem;
        height: 66.7rem;
        padding: 2.4rem;
        border-radius: 0%;
    }
}