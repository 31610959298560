@import "assets/styles/main";

.dob-container {
    margin-bottom: 1.2rem;
    width: 30.4rem;

    .dob {
        color: $color-black-font;
        width: 100%;
        height: 5.2rem;
        border: 0.1rem solid $color-primary;
        border-radius: 5px;
        font-family: Poppins, sans-serif;
        padding-left: 1rem;
        font-size: 1.6rem;

        &:focus {
            outline: none;
            border: 0.1rem solid $color-light-blue-secondary;
        }

        &--error {
            border-color: $color-danger;

            &:focus {
                outline: none;
                border: 1px solid $color-danger;
            }
        }

        &::placeholder {
            color: $color-gray-bg2;
        }
    }

    .dateField-label-wrapper {
        margin-bottom: 0.5rem;

        .dateField-label {
            margin-left: 0.1rem;
            font-family: Poppins-Medium, sans-serif;
            font-size: 1.4rem;
            color: $color-primary;
        }
    }

    @include styles-for-device-size(sm) {
        width: 100%;
    }
}