@import "assets/styles/main";

.dateinput-container {
  margin-bottom: 1.2rem;
  width: 100%;

  &__wrapper {
    margin-top: 0.85rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .dateinput {
    height: 5.2rem;
    width: 30.4rem;
    color: black;
    border: 1px solid #091644;
    border-radius: 5px;
    font-family: Poppins-Regular, sans-serif;
    font-size: 1.6rem;
    padding-left: 1rem;
  
    &--error {
      border-color: $color-danger;
      border-radius: 5px;
  
      &:focus {
        outline: none;
        border: 1px solid $color-danger;
      }
    }
  
    &:focus {
      outline: none;
      border: 1px solid $color-light-blue-secondary;
    }
  }

  @include styles-for-device-size(xs) {
    .dateinput {
      width: 100%;
    }
  }
}




