@import "assets/styles/main";
@import "pages/application/application";

$progressbar-left-padding: 8rem;

.status-bar {
  width: calc(100vw - (2 * $progressbar-left-padding));
  height: 0.6rem;
  background-color: $color-light-gray-bg;
  margin-left: $progressbar-left-padding;

  &__shaded-progress {
    height: 100%;
    background-color: $color-yellow;
    transition: width 2s ease;
    border-radius: 1rem;
  }

  @include styles-for-device-size(sm) {
    width: 100vw;
    margin-left: 0;
  }
}
