@import "assets/styles/main";

.uqual-ref-modal {
  .logos-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

    [class$="-logo"] {
      margin: 1rem 0;
    }

    .naf-logo {
      height: 4.5rem;
    }

    .uqual-logo {
      margin-top: 1.5rem;
      height: 3rem;
    }
  }

  .modal__body {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding-top: 0;
  }

  .modal__content {
    width: 42%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .uqual-modal {
    &__close-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: end;
    }

    &__close-button {
      text-align: right;
      height: 1.5em;
      width: 1.5em;
      border: none;
      background: none;
      cursor: pointer;
      margin-top: 1.5rem;
      margin-right: 1.5rem;
    }

    &__title {
      font-size: 2rem;
      font-weight: normal;

      &.thanks {
        font-weight: 100;
      }
    }

    &__subtitle {
      font-size: 1.7rem;
      font-weight: normal;
      margin: 2rem 0 0.75rem;
      text-align: left;
    }

    &__success {
      hr {
        border: none;
        height: 0.1rem;
        color: $color-tertiary-blue;
        background-color: $color-tertiary-blue;
        margin: 3rem 0;
      }

      h5 {
        font-weight: 500;
      }

      .rating {
        text-align: left;
        color: $color-light-blue-secondary;
        font-size: 1.5rem;
        margin-top: 0.5rem;

        .fa-star {
          margin: 0 0.15rem;
        }
      }
    }

    &__testimonial {
      font-size: 1.3rem;
      font-style: italic;
      color: $color-light-black;
      text-align: left;
      line-height: 2rem;
    }

    &__content {
      background-color: white;
      margin: 0 1.5rem 5rem;

      .read-disclaimer-button {
        font-size: 1.5rem;
        color: $color-light-blue-secondary;
        margin-top: 0.8rem;
        margin-bottom: 0.8rem;

        span {
          margin-left: 0.5rem;

          svg {
            vertical-align: text-bottom;
            height: 2rem;
          }
        }
      }
    }

    &__text {
      font-size: 1.5rem;
      text-align: justify;
      padding-bottom: 0.5em;
      line-height: 2rem;
    }

    &__subtext {
      font-size: 1.5rem;
    }

    &__action-button {
      margin: 2rem 0;

      button {
        font-size: 1.5rem;
        height: 5rem;
        width: 30rem;
      }
    }

    &__error-image {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: end;
    }
  }

  .error {
    * {
      text-align: left;
    }

    img {
      max-height: 15rem;
    }
  }
}
