@import "assets/styles/main";

.tooltip {
  position: absolute;
  top: 3rem;
  border: 0.1rem solid $color-yellow;
  background-color: #fdf7ef;
  border-color: $color-yellow;
  margin: 1rem;
  padding: 1.5rem;
  border-radius: 0.8rem;
  margin-top: 3.8rem;

  .down::after,
  .up::after,
  .right::after,
  .left::after,
  .down::before,
  .up::before,
  .right::before,
  .left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
  }

  .down::after {
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 1.6rem solid $color-yellow;
    bottom: -1.5rem;
    left: 8rem;
    top: -1.7rem;
  }

  .up::after {
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-top: 1.6rem solid $color-yellow;
    bottom: -1.5rem;
    left: 8rem;
  }

  .right::after {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.6rem solid $color-yellow;
    right: -1.55rem;
    bottom: 1.55rem;
  }

  .left::after {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-right: 1.6rem solid $color-yellow;
    left: -1.55rem;
    bottom: 1.55rem;
  }

  .up::before {
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-top: 1.6rem solid#fdf7ef;
    bottom: -1.4rem;
    left: 8rem;
    z-index: 1;
  }

  .down::before {
    border-left: 1.2rem solid transparent;
    border-right: 1.2rem solid transparent;
    border-bottom: 1.6rem solid#fdf7ef;
    left: 8rem;
    bottom: -1.4rem;
    top: -1.5rem;
    z-index: 1;
  }

  .right::before {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-left: 1.6rem solid#fdf7ef;
    right: -1.4rem;
    bottom: 1.5rem;
    z-index: 1;
  }

  .left::before {
    border-top: 1.2rem solid transparent;
    border-bottom: 1.2rem solid transparent;
    border-right: 1.6rem solid#fdf7ef;
    left: -1.4rem;
    bottom: 1.5rem;
    z-index: 1;
  }

  .tooltip--primary {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 19rem;

    &__icon {
      margin-top: 0.5rem;
      margin-left: 16.5rem;
      font-size: 1.4rem;
      cursor: pointer;
    }

    &__info {
      font-size: 1.4rem;
      padding-left: 2rem;
    }

    .Checkbox_wrapper label {
      font-size: 1.2rem;
      vertical-align: 0.125em;
    }

    .Checkbox_wrapper input {
      margin-top: 1.34rem;
    }
  }
}