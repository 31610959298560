@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.currency {
  width: 30.4rem;
  height: 5.2rem;
  background: $color-white 0% 0% no-repeat padding-box;
  border: 0.1rem solid $color-gray-bg;
  margin-top: 0.5rem;
  opacity: 1;
  display: flex;
  flex-direction: row;
  font: normal normal normal 1.4rem/4.4rem Poppins-Medium;
  letter-spacing: 0;
  color: $color-gray-bg;
  border-radius: 0.5rem;

  span {
    border-left: 0.1rem solid $color-gray-bg;
    height: 100%;
  }

  &:focus-within {
    border: 0.15rem solid $color-light-blue-secondary;
    outline: none;

    span {
      border-left: 0.15rem solid $color-secondary;
      height: 100%;
    }
  }

  &--field {
    width: 30.4rem;
    height: auto;
    margin-bottom: 2rem;

    &__label {
      font-size: 1.4rem;
      font-family: Poppins-Medium, sans-serif;
      letter-spacing: 0;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      color: $color-primary;

      &__info-icon {
        display: flex;
        align-items: center;
        flex-direction: column;
        color: $color-primary;
        cursor: pointer;

        .tooltip {
          top: 25rem;
          left: 32rem;
          height: 3.5rem;
          width: 17rem;
          display: none;
          background-color: $color-off-white;
          border: none;
          border-radius: 0;
          box-shadow: 0 0.3rem 0.6rem #00000029;
          padding-left: 0;
          padding-right: 0;

          .tooltip--primary {
            text-align: center;

            &__info {
              font-size: 1.2rem;
              font-family: Roboto, sans-serif;
              line-height: 1.6rem;
              text-align: center;
              align-self: start;
            }
          }

          .up::before,
          .up::after {
            left: 10%;
          }
          
          .up::before {
            border-top: 1.6rem solid $color-off-white;
          }

          .up::after {
            border-top: 1.6rem solid #00000029;
          }

        }

        &:hover {
          .icon {
            color: $color-secondary;
          }

          .tooltip {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }

  &--field-one {
    height: 8rem;
    width: 35.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &--amount {
    border: none;
    margin: auto;
    width: 70%;
    height: 90%;
    padding-left: 2rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-family: Poppins, sans-serif;
    color: #222;

    &:focus {
      outline: none;
    }
  }

  &--cashamount {
    border: none;
    padding-left: 2rem;
    margin: auto;
    width: 90%;
    height: 90%;

    &:focus {
      outline: none;
    }
  }

  &--percentage {
    width: 30%;
    border: none;
    margin: auto;
    height: 90%;
    padding-right: 2rem;
    text-align: right;
    cursor: pointer;
    font-size: 1.6rem;
    font-family: Poppins, sans-serif;
    color: #222;

    &:focus {
      outline: none;
    }
  }
}

@include styles-for-device-size(sm) {
  .currency {
    width: 100%;
    margin-top: 1rem;

    &--field {
      margin-bottom: 2rem;
      width: 97%;
      gap: 0.8rem;

      &__label__info-icon {
        .tooltip {
          top: 24rem;
          left: 15.4rem;

          .up::before,
          .up::after {
            left: 45%;
          }
        }
      }
    }
  }
}

.amount-line-items {
  width: 100%;

  .amount-line-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2.4rem;

    p {
      font-size: 1.6rem;
      font-family: Poppins, sans-serif;
      color: $color-secondary;
    }

    label {
      font-size: 1.6rem;
      font-family: Poppins, sans-serif;
      color: $color-dark-black;
    }
  }
}
