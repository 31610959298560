@import "assets/styles/main";

.logout {
  cursor: pointer;
  display: flex;
  align-items: center;

  &__description {
    color: $color-white;
    font-family: Roboto, sans-serif;
    white-space: nowrap;
  }

  &__icon {
    color: $color-off-white;
    margin-left: 1.1rem;
  }

  @include styles-for-device-size(xs) {
    &__icon {
      font-size: 2rem;
    }

    .poweroff {
      display: flex;
      padding-left: 4.3rem;
      padding-right: 6.2rem;
      color: $color-primary;

      &__icon {
        font-size: 1.6rem;
        margin-right: 0.8rem;
      }

      &__text {
        color: $color-primary;
        font-size: 1.4rem;
        font-family: Poppins-Regular, sans-serif;
      }
    }

    .bracketicon {
      color: $color-white;
    }
  }
}
