@import "assets/styles/main";

.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &_title {
    color: $color-primary;
    font-size: 4.8rem;
    font-family: Poppins-Medium, sans-serif;
    margin-bottom: 3.2rem;
    margin-top: 6.4rem;
    text-align: center;
  }

  &_description {
    color: $color-dark-black;
    display: flex;
    flex-direction: column;
    font-size: 1.8rem;
    font-family: Roboto, sans-serif;
    line-height: 2.8rem;
    margin-bottom: 3.2rem;
    text-align: center;
    width: 63%;
    max-width: 115rem;

    &__one {
      margin-bottom: 1.6rem;
    }
  }

  .btn {
    width: 30.4rem;
  }

  &_graphic {
    margin-top: 6.9rem;
  }

  .footer {
    margin-top: 2.6rem;
  }

  @include styles-for-device-size(xs) {
    height: 100vh;
    margin: 0;
    padding: 0;

    &::after {
      content: "";
      display: block;
      height: 20rem;
    }

    .content {
      padding-bottom: 26.6rem;
    }

    &_title {
      font-size: 2.4rem;
      margin-top: 4rem;
      margin-bottom: 2.4rem;
    }

    &_description {
      width: 100%;
      line-height: 2.4rem;
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
      text-align: center;
      padding: 0 1.6rem;

      &__one {
        margin-bottom: 1.6rem;
      }
    }

    &_graphic {
      margin-top: 3.2rem;
      padding: 0 1.6rem;
    }

    .footer {
      margin-top: 12rem;
      position: sticky;
      bottom: -13.5rem;
      height: 20rem;
    }
  }
}