@import "assets/styles/abstracts/mixins";
@import "assets/styles/abstracts/variables";

.mfa-container {
  height: 100rem;
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    color: $color-primary;
    font-family: Poppins, sans-serif;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: 500;
    line-height: 4.2rem;
    margin-bottom: 3.2rem;
  }

  .box-container {
    display: flex;
    width: 40rem;
    padding: 0.4rem;
    align-items: flex-start;
    border-radius: 0.6rem;
    border: 0.1rem solid $color-light-gray-bg;
    background: $color-white;
    box-shadow: 0 0.1rem 0.2rem 0 #0000000d;
    margin-bottom: 1.6rem;

    &:hover {
      cursor: pointer;
    }

    .icon-mobile,
    .icon-envelope {
      color: $color-primary;
      font-size: 4rem;
      background-color: $color-off-white;
      border-radius: 0.6rem 0 0 0.6rem;
    }

    .icon-mobile {
      padding: 3rem 3.75rem;
    }

    .icon-envelope {
      padding: 3rem;
    }

    .desc {
      display: flex;
      padding: 0 1.6rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.6rem;
      flex: 1 0 0;
      align-self: stretch;
      text-wrap: nowrap;
      
      &__header {
        color: $color-dark-black;
        text-align: center;
        font-family: Poppins;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
      }

      &__message {
        color: $color-dark-black;
        font-family: Roboto;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        text-align: start;
      }
    }
    
    .arrow-right {
      font-size: 1.8rem;
      display: flex;
      width: 3.7rem;
      padding: 0 1.6rem 0 1.1rem;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1.6rem;
      flex-shrink: 0;
      align-self: stretch;
    }
  }

  @include styles-for-device-size(xs) {
    margin-top: 3rem;
    width: 100%;

    &__header {
      font-size: 2.4rem;
      font-weight: 500;
      font-style: normal;
      line-height: 3rem;
      text-align: center;
      margin-bottom: 2.4rem;
    }

    .box-container {
      margin-bottom: 1.6rem;
      width: 92%;

      .icon-mobile {
        padding: 3rem 1.95rem;
      }
      
      .arrow-right {
        font-size: 1.8rem;
        margin-right: 2rem;
      }

      .icon-envelope {
        padding: 3rem 1.2rem;
      }
    }
  }
}