@import "assets/styles/main";

.footerContainer{
  display: flex;
  justify-content: center;
}

.footerdashboard, .footerdocument{
  width: 100%;

  .tech-supportContainer{
    display: flex;
    justify-content: center;
    background-color: $color-light-black-dd;
  }

  .tech-support {
    display: flex;
    align-items: center;
    background-color: $color-light-black-dd;
    color: $color-white;
    height: 2.4rem;
    font-size: 1.2rem;
    text-align: left;
    white-space: nowrap;

    &__phone-number {
      margin-right: 3rem;
    }

    @include styles-for-device-size(sm) {
      flex-direction: column;
      height: 8.8rem;
      justify-content: center;

      &__phone-number {
        margin: 0 0 0.8rem;
      }
    }
  }

  @include styles-for-device-size(sm) {
    .tech-support{
      width: 100%;
    }
  }
}

.footerdashboard{
  margin-top: 0;

  .tech-support{
    width: 115rem;
  }
}

.footerdocument{
  margin-top: 6.7rem;

  .tech-support{
    width: 128rem;
  }
}

.footer {
  width: 100%;
  margin-top: 6.7rem;

  .tech-supportContainer{
    display: flex;
    justify-content: center;
    background-color: $color-light-black-dd;
  }

  .tech-support {
    display: flex;
    align-items: center;
    background-color: $color-light-black-dd;
    width: 115rem;
    color: $color-white;
    height: 2.4rem;
    font-size: 1.2rem;
    text-align: left;
    white-space: nowrap;

    &__phone-number {
      margin-right: 3rem;
    }

    @include styles-for-device-size(sm) {
      flex-direction: column;
      height: 8.8rem;
      justify-content: center;

      &__phone-number {
        margin: 0 0 0.8rem;
      }
    }
  }

  @include styles-for-device-size(sm) {
    position: relative;
    margin-top: 2.4rem;

    .tech-support{
      width: 100%;
    }
  }
}
