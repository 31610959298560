@import "assets/styles/main";

.file_uploader-modal {
  .modal__content {
    max-width: 86.8rem;
    width: auto;
    max-height: 52.8rem;

    .modal__header {
      border-radius: 1rem 1rem 0 0;
      padding: 3.2rem;
    }

    .modal__body {
      border-radius: 0 0 1rem 1rem;
      padding: 0 7.5rem 1.6rem;
    }
  }
}

.file_uploader-container {
  .dropzone {
    background-image: repeating-linear-gradient(
        0deg,
        $color-gray-two,
        $color-gray-two 10px,
        transparent 10px,
        transparent 20px,
        $color-gray-two 20px
      ),
      repeating-linear-gradient(
        90deg,
        $color-gray-two,
        $color-gray-two 10px,
        transparent 10px,
        transparent 20px,
        $color-gray-two 20px
      ),
      repeating-linear-gradient(
        180deg,
        $color-gray-two,
        $color-gray-two 10px,
        transparent 10px,
        transparent 20px,
        $color-gray-two 20px
      ),
      repeating-linear-gradient(
        270deg,
        $color-gray-two,
        $color-gray-two 10px,
        transparent 10px,
        transparent 20px,
        $color-gray-two 20px
      );
    background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
    min-height: 22rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2.2;

    .icon {
      font-size: 8rem;
      color: $color-primary;
      margin-top: 3.1rem;
      margin-bottom: 0.8rem;
    }

    .p1 {
      font-size: 1.8rem;
      color: $color-primary;
      font-family: Roboto-Medium, sans-serif;
      text-align: center;
      line-height: 2.5rem;
      margin-bottom: 0.8rem;
      height: 5.2rem;
    }

    .disabled {
      .icon {
        color: $color-light-gray-bg;
      }

      .p1 {
        color: $color-light-gray-bg;
      }
    }
  }

  .files {
    display: flex;
    flex-direction: row;
    margin-bottom: 3.1rem;
  }

  .size {
    display: flex;

    &__p3 {
      font-size: 1.4rem;
      font-family: Roboto-Bold, sans-serif;
      color: $color-light-black;
    }

    &__p4 {
      font-size: 1.4rem;
      font-family: Roboto-Regular, sans-serif;
      margin-left: 0.4rem;
    }
  }

  .p2 b {
    font-family: Roboto-Bold, sans-serif;
  }

  .p2 {
    font-size: 1.4rem;
    color: $color-light-black;
    font-family: Roboto, sans-serif;
    margin-left: 0.8rem;
  }

  .p5 {
    font-size: 1.4rem;
    color: $color-light-black;
    font-family: Roboto, sans-serif;
  }

  .p16_error {
    font-size: 1.6rem;
    color: $color-danger;
    margin-bottom: 0.975rem;
    font-family: Poppins-Medium, sans-serif;
  }

  .p16_error::first-letter {
    text-transform: capitalize;
  }

  .p18 {
    font-size: 1.8rem;
    color: $color-secondary;
    margin-bottom: 1.475rem;
    font-family: Roboto, sans-serif;
  }

  .p18_error {
    font-size: 1.8rem;
    color: $color-danger;
    margin-bottom: 1.475rem;
    font-family: Roboto, sans-serif;
  }

  .p32_error {
    margin-top: 5.8rem;
    font-size: 3.2rem;
    color: $color-danger;
    margin-bottom: 0.8rem;
    font-family: Poppins-Medium, sans-serif;
    text-align: center;
  }

  .btn {
    margin: 0;
    margin-bottom: 1.6rem;
  }

  .maxfile-error {
    font-family: Roboto-Regular, sans-serif;
    font-size: 1.4rem;
    color: $color-danger;
    text-align: left;
    margin: 1.6rem 0;
  }

  .document_uploader-container {
    margin-top: 0.8rem;
    margin-bottom: 2rem;
    max-height: 22rem;
    overflow-x: hidden;
    overflow-y: auto;

    .document-upload:first-child {
      margin-top: 0.6rem;
    }

    .document-upload:last-child {
      margin-bottom: 0.6rem;
    }

    &::-webkit-scrollbar {
      width: 0.9rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0.5rem #d1d4e3;
    }

    &::-webkit-scrollbar-thumb {
      height: 6.1rem;
      background: #a3a6b8;
      border-radius: 1rem;
    }
  }

  .footer-btn {
    display: flex;
    justify-content: flex-end;
    margin-left: 0;

    .btn {
      margin-right: 1rem;
      margin-left: 0;
    }
  }

  .number {
    display: flex;
    font-size: 1.4rem;
    font-family: Roboto-Bold, sans-serif;
    color: $color-dark-black;

    &__count {
      color: $color-secondary;
      font-family: Roboto-Bold, sans-serif;
    }
  }

  .success-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 96%;
    margin-left: 2%;
    height: 35.3rem;

    .p32 {
      font-family: Poppins-Medium, sans-serif;
      font-size: 3.2rem;
      color: $color-primary;
      text-align: center;
      margin-bottom: 3.2rem;
    }

    .btn {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  @include styles-for-device-size(sm) {
    .dropzone {
      background-image: none;
      width: 100%;

      .icon {
        margin-top: 3.2rem;
        font-size: 6.4rem;
      }

      .p1 {
        margin-top: 1rem;
        font-size: 1.6rem;
      }

      .p2 {
        text-align: center;
        font-size: 1.2rem;
        font-family: Roboto, sans-serif;
      }

      .p5 {
        text-align: center;
        font-size: 1.2rem;
        font-family: Roboto, sans-serif;
      }

      .p2 p {
        font-family: Poppins-medium, sans-serif;
      }

      .size {
        &__p3 {
          font-family: Poppins-medium, sans-serif;
        }
      }
    }

    .document_uploader-container {
      margin-bottom: 0.6rem;
    }

    .files {
      margin-bottom: 0;
    }

    .maxfile-error {
      text-align: center;
      margin-bottom: 0.8rem;
    }

    .cloud-icon {
      font-size: 6.4rem;
      margin-bottom: 2.4rem;
      color: $color-primary;
    }

    .icon-container {
      display: flex;
      gap: 5.1rem;
      margin-top: 1.6rem;
      margin-bottom: 4.5rem;

      &__icon1 {
        color: white;
      }

      &__text1 {
        font-size: 1.4rem;
        font-family: Poppins-Medium, sans-serif;
        width: 5rem;
        height: 3.6rem;
        color: $color-primary;
        margin-top: 2rem;
        line-height: 1.3;
      }

      &__text2 {
        font-size: 1.4rem;
        font-family: Poppins-Medium, sans-serif;
        color: $color-primary;
        line-height: 1.3;
        margin-top: 2rem;
      }
    }

    .camera-icon {
      margin-top: 1.5rem;
      font-size: 2.4rem;
      color: white;
    }

    .folder-open-icon {
      margin-top: 1.5rem;
      font-size: 2.4rem;
      color: white;
    }

    .outer-circle {
      border-radius: 50%;
      background: $color-primary;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 5.6rem;
      height: 5.6rem;
      cursor: pointer;
    }

    .footer-btn {
      display: flex;
      flex-direction: column;
      margin: 0;

      .btn {
        width: 100%;
        margin-top: 1rem;
      }
    }

    .number {
      flex-direction: column;
      margin-top: 1rem;
      line-height: 1.5;
    }

    .success-message {
      .p32 {
        font-size: 2.4rem;
      }
    }

    .disabled {
      .icon {
        color: $color-light-gray-bg;
      }

      .icon-container__icon1 {
        color: $color-light-gray-bg;
      }

      .icon-container__text1 {
        color: $color-light-gray-bg;
      }

      .icon-container__icon2 {
        color: $color-light-gray-bg;
      }

      .icon-container__text2 {
        color: $color-light-gray-bg;
      }

      .outer-circle {
        background-color: $color-light-gray-bg;
      }
    }

    .file-input__hidden {
      position: absolute;
      left: -999px;
    }
  }
}
