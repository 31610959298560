@import "assets/styles/main";

.voa-popup {
  .modal {
    .modal__content {
      width: 52%;

      .modal__body {
        display: flex;
        justify-content: center;

        iframe {
          height: 65rem;
          width: 90%;
        }
      }
    }
  }

  @include styles-for-device-size(sm) {
    .modal {
      .modal__content {
        width: 96%;

        .modal__body {
          padding: 0;

          iframe {
            width: 100%;
          }
        }
      }
    }
  }
}