@import "assets/styles/main";

.auto-suggestion {
  flex: 6;
  margin-bottom: 2rem;
  grid-column: 1 / 2;
  grid-row: 2 / 3;

  .dropdown__wrapper {
    margin: 1rem 1rem 0 0;
    position: relative;
  }

  .dropdown {
    padding: 1rem;
    border-radius: 0.5rem;
    height: 5.2rem;
    border: 0.1rem solid $color-primary;
    background-color: $color-white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Poppins, sans-serif;
    font-size: 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: auto;

    &:focus {
      outline: none;
      border: 0.1rem solid $color-light-blue-secondary;
    }

    &--error {
      border-color: $color-danger;
      border-radius: 0.5rem;

      &:focus {
        outline: none;
        border: 0.1rem solid $color-danger;
      }
    }

    & + .tooltip {
      position: absolute;
      visibility: hidden;
      top: -8rem;
      background-color: $color-off-white;
      border-color: $color-light-gray-bg;
      color: $color-dark-black;
      transition: visibility 0s, opacity 0.2s linear;
      white-space: nowrap;
      z-index: 10;
      border-radius: 1rem;
    }
  }

  .tooltip .up::before {
    position: absolute;
    left:1rem;
    border-top: 1.6rem solid $color-off-white;
  }

  .tooltip .up::after {
    position: absolute;
    left:1rem;
    border-top: 1.6rem solid $color-off-white
  }

  .tooltip--primary {
    width: max-content;
  }

  .tooltip--primary__info {
    padding-left: 0;
    font-family: Roboto-Regular, sans-serif;
  }

  .dropdown::placeholder {
    color: $color-gray-bg2;
  }

  .dropdown-items {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.3rem 1rem $color-black;
    border-radius: 0.5rem;
    position: absolute;
    top: 115%;
    width: 100%;
    background-color: $color-white;
    z-index: 1;
    cursor: pointer;
  }

  .select-item {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: flex-start;
    font-family: Poppins-Medium, sans-serif;
  }

  .select-item:hover {
    background-color: $color-light-gray;
  }

  .select-item:first-child {
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .input-label {
    margin-left: 0;
  }
}

.auto-suggestion .dropdown:hover + .tooltip {
  visibility: visible;
}

@include styles-for-device-size(sm) {
  .auto-suggestion {
    grid-column: 1 / span 2;
    margin-bottom: 1.6rem;

    .dropdown__wrapper {
      margin: 1rem 0;
    }
  }
}
