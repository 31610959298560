@import "assets/styles/abstracts/variables";

.loading-spinner {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;

  .text {
    font-size: 2rem;
    display: inline;
  }
}
