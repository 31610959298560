@import "assets/styles/main";

.section-bar {
  background-color: $color-off-white;

  &__wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    list-style: none;
    justify-content: center;
    margin-top: 1rem;
  }

  &__item {
    color: $color-primary;
    margin: 1.6rem 0 1.6rem 1.6rem;
    line-height: 0.5rem;
    padding-right: 1.3rem;
    font-family: Poppins, sans-serif;

    &--not-started {
      display: flex;
      align-items: center;
      opacity: 0.35;
    }

    &--inprogress {
      display: flex;
      align-items: center;
    }

    &--completed {
      display: flex;
      align-items: center;

      &__check {
        color: $color-light-blue-secondary;
      }
    }

    &--name {
      font-size: 1.4rem;
      padding-left: 0.8rem;
      text-transform: capitalize;
    }

    &--icon {
      font-size: 2rem;
    }
  }

  &__line:last-child {
    border-right: none;
  }

  @include styles-for-device-size(sm) {
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &__wrapper {
      display: flex;
      justify-content: flex-start;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      color: $color-primary;
      margin: 2.2rem 0 0;

      &--name {
        font-weight: bold;
      }
    }

    &__line {
      border: none;
    }

    &__item:first-child {
      margin-left: 1.3rem;
    }
  }
}