@import "assets/styles/main";

.advertisement {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  background-color: $color-off-white;

  &-image {
    position: relative;
    display: block;

    &-logo {
      position: absolute;
      top: 13%;
      left: 26.5%;
      width: 14.1rem;
      height: 3.5rem;
    }

    &-img-text {
      &-block {
        text-align: center;
        position: absolute;
        height: 27%;
        bottom: 6%;
        background-color: rgb(9 22 68 / 70%);
        left: 26.6%;
      }

      padding: 0.5rem 0 0;
      font-family: Poppins,
      sans-serif;
      color: $color-white;
      font-size: 1.4rem;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    text-align: left;

    &__text {
      font-size: 1.4rem;
    }

    &__subtext {
      font-size: 1.4rem;
    }
  }

  &-footer {
    display: flex;
    margin-left: 6.4rem;
  }
}

@include styles-for-device-size(sm) {
  .advertisement {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-image {
      &-logo {
        left: 5%;
        width: 18.7rem;
        height: 4.6rem;
      }

      &-img-text {
        padding: 5.2%;

        &-block {
          left: 5%;
          width: 90%;
          bottom: 1%;
        }
      }
    }

    &-footer {
      margin-left: 0;
    }
  }
}