@import "assets/styles/main";


.input-container {
  margin-bottom: 1.2rem;

  .input {
    color: $color-black-font;
    width: 100%;
    height: 5.2rem;
    border: 1px solid $color-gray-bg;
    border-radius: 5px;
    font-family: Poppins, sans-serif;
    padding-left: 1rem;
    font-size: 1.6rem;

    
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 1rem;
      position: relative;
    }

    &.input--read-only {
      border-color: $color-light-gray-bg;
    }

    &.input--error, &.input-error-border{
      border-color: $color-danger;
      border-radius: 5px;

      &:focus {
        outline: none;
        border: 1px solid $color-danger;
      }
    }

    &--icon {
      position: absolute;
      right: 1.5rem;
      font-size: 1.8rem;
      font-family: Roboto, sans-serif;
      top: 1.5rem;
      color: $color-light-blue-secondary;

      &--pointer {
        cursor: pointer;
      }
    }

    &:focus {
      outline: none;
      border: 1px solid $color-light-blue-secondary;
    }

    &__subtext {
      margin-top: 0.9rem;
      font-size: 1.4rem;
      font-family: Roboto, sans-serif;
    }
  }

   
  .input::placeholder {
    color: $color-gray-bg2;
  }

  .input-label {
    margin-left: 10px;
    font-family: Poppins-Medium;
    font-size: 1.4rem;
    color: $color-primary;
    text-wrap: nowrap;

    &__wrapper {
      width: 100%;
      display: flex;
      align-items: baseline;
    }
  }

  // These styles remove the up/down arrows from the number input
  // Chrome, Safari, Edge, Opera
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  // Firefox
  input[type="number"] {
    appearance: none;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"]:focus {
    font-size: 1.6rem;
  }
}

.input-city {
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  justify-self: auto;
}

.input-zipCode {
  width: 16.5rem;
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  justify-self: end;
}

@include styles-for-device-size(sm) {
  .input-city {
    display: grid;
    grid-column: 1 / span 2;
    margin-bottom: 1.6rem;
  }
  
  .input-zipCode {
    width: 13rem;
    grid-column: 2 / 3;
    grid-row: 4 / 5;
    justify-self: end;
  }
}
