@import "assets/styles/main";

.naf-insurance {
    display: flex;
    height: 8.8rem;
    justify-content: space-between;
    gap: 1.6rem;
    margin: 0 2rem 0 1.7rem;

    &__left-wrapper {
        display: flex;
        width: 92.9rem;
        gap: 3.2rem;
    }

    &__content {
        font-family: Roboto, sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 2.0rem;
        text-align: left;
        position: relative;

        &__disclaimer {
            font-family: Roboto, sans-serif;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 2.0rem;
            text-align: left;
            color: $color-secondary;
            cursor: pointer;
            margin-left: .5rem;
            position: relative;
            text-decoration: underline;

            &__tooltip {
                display: flex;
                flex-direction: column;
                position: absolute;
                bottom: 100%;
                padding: 1.6rem;
                box-sizing: border-box;
                z-index: 1000;
                width: 40rem;
                height: 19.6rem;
                border-radius: .6rem 0 0;
                border: .1rem solid $color-light-gray-bg;
                background: $color-white;
                box-shadow: 0 .4rem .4rem 0 $color-translucent-black;
                font-family: Roboto-Medium, sans-serif;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 2rem;
                text-align: left;
                color: $color-dark-black;
                margin-bottom: 1rem;

                &.purchase {
                    left: 0;
                }

                &.refinance {
                    right: 0;
                }

                &__close {
                    align-self: flex-end;
                    gap: 0;
                }

                &__content {
                    width: 36.8rem;
                }
            }
        }
    }

    &__cta {
        button {
            width: 11.1rem;
            height: 3.4rem;
            min-height: 3.4rem;
            gap: 1.6rem;
            font-family: Poppins, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.6rem;
            padding: 0;
        }
    }

}


@include styles-for-device-size(sm) {
    .naf-insurance {
        flex-direction: column;
        width: 100%;
        height: auto;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0 1.6rem 2.4rem;
        gap: 2.5rem;

        &__left-wrapper {
            flex-direction: column;
            width: 100%;
        }

        &__content {
            text-align: center;

            &__disclaimer {
                display: block;
                text-align: center;

                &__tooltip {
                    display: flex;
                    position: fixed;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    padding: 2rem;
                    flex-direction: column;
                    gap: 2rem;

                    &__close {
                        width: 1.6rem;
                        height: 1.6rem;
                    }

                    &__content {
                        width: 100%
                    }
                }

            }
        }
    }
}