@import "assets/styles/main";

.close_date_details {
  text-align: center;
  font-family: Poppins, sans-serif;
  margin-bottom: 16px;
  font-size: 14px;
  color: #333;

  span {
    font-size: 16px;
    color: $color-primary;
    font-family: Roboto, sans-serif;
  }
}

.progress_bar_container {
  width: 100%;

  &.highlight {
    position: relative;
    left: 0;
    padding: 2rem;
    border: 0.5rem solid $color-light-blue-secondary;
    z-index: 2;
    background-color: $color-pure-white;

    .tooltip-popup {
      position: absolute;
      z-index: 2;
      left: 10rem;
      bottom: -21rem;
    }
  }
}

.progress_bar {
  width: 100%;
  position: relative;

  &_icon_group {
    display: flex;
    justify-content: space-between;
  }

  &_bg {
    height: 10px;
    background-color: #999;
    position: absolute;
    border-radius: 20px;
    width: 100%;
    top: 15px;
    z-index: -1;

    &_active {
      background-color: $color-primary;
      height: inherit;
      border-radius: inherit;
    }
  }

  .icon_label_sm {
    margin-top: 26px;
    display: none;
    color: $color-primary;
    font-size: 14px;
    font-family: Poppins-Medium, sans-serif;
  }
}

.icon_wrapper {
  display: flex;
  flex-direction: column;
  width: 110px;
  align-items: center;

  .tooltip {
    top: -14.5rem;
    height: 9.1rem;
    width: 25.1rem;
    display: none;
    background-color: #fff;
    border: 1px solid #b1afaf;
    padding-left: 0;
    padding-right: 0;

    .tooltip--primary {
      text-align: left;

      &__info {
        font-size: 1.2rem;
        font-family: Roboto, sans-serif;
        line-height: 1.6rem;
      }
    }
  }

  .up::after,
  .up::before {
    left: 45%;
  }

  .up::before {
    border-top: 1.6rem solid #fff;
  }

  .up::after {
    border-top: 1.6rem solid #b1afaf;
  }

  .outer-circle {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: #999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .inner-circle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: inherit;
    border: 1px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    color: #fff;
    height: 16px;
  }

  .icon_label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    text-align: center;
    color: #333;
    margin-top: 4px;
  }

  .outer-circle:hover {
    background: $color-secondary;
  }

  .active {
    background-color: $color-primary;
  }

  .active:hover {
    background-color: $color-secondary;
  }

  &:hover {
    .tooltip {
      display: flex;
    }
  }
}

.icon_wrapper:first-child {
  align-items: flex-start;
  width: min-content;

  .icon_label {
    text-align: left;
  }

  .tooltip {
    margin-left: 0;
  }

  .up::before,
  .up::after {
    left: 3%;
  }
}

.icon_wrapper:last-child {
  align-items: flex-end;
  width: min-content;

  .icon_label {
    text-align: right;
  }

  .tooltip {
    margin-right: 0;
  }

  .up::before,
  .up::after {
    left: 87%;
  }
}

@include styles-for-device-size(sm) {
  .close_date_details {
    margin: 1.6rem 0;
  }

  .progress_bar_container.highlight {
    padding: 1rem 0 0;
    display: flex;
    justify-content: center;

    .tooltip-popup {
      top: 15rem;
      left: 5%;
    }
  }

  .progress_bar_container {
    display: flex;
    justify-content: center;
  }

  .progress_bar {
    margin-right: 2%;
    width: 90%;
    margin-bottom: 12px;

    .progress_bar_bg {
      width: 103%;
      margin-left: 0;
      height: 5px;
      position: relative;
      top: 5.61px;
    }

    .icon_label_sm {
      display: flex;
      text-align: left;

      &_icon {
        margin-right: 8px;
        margin-top: 3px;
      }
    }
  }

  .icon_wrapper {
    width: min-content;

    .tooltip {
      display: none;
    }

    .outer-circle {
      width: 32px;
      height: 32px;
    }

    .inner-circle {
      width: 26px;
      height: 26px;
    }

    .icon_label {
      display: none;
    }

    .icon {
      height: 13px;
      width: 12px;
    }
  }

  .icon_wrapper:nth-child(2) {
    .up::before {
      left: 24%;
    }

    .up::after {
      left: 24%;
    }

    .tooltip {
      left: -1.7rem;
    }
  }

  .icon_wrapper:nth-child(6) {
    .up::before {
      left: 61%;
    }

    .up::after {
      left: 61%;
    }

    .tooltip {
      right: -2.8rem;
    }
  }
}
