@import "assets/styles/main";

$application-left-padding: 14rem;

.application {
  display: flex;
  flex-direction: column;
  height: 100%;

  &-header {
    background-color: $color-off-white;

    .clickablesContainer {
      display: flex;
      justify-content: center;
    }

    &__clickables {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1.2rem 0 1.6rem;
      width: 115rem;

      .previous-question-button {
        background-color: inherit;
        border: none;
        color: $color-secondary;
        font-size: 1.6rem;
        font-family: Poppins-Medium, sans-serif;

        .arrow-left {
          margin-right: 0.8rem;
        }

        &:hover {
          cursor: pointer;
        }

        &.hidden {
          visibility: hidden;
        }
      }
    }
  }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;

    .questionContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: $color-off-white;
    }

    &__question {
      flex-grow: 1;
      padding-bottom: 3.2rem;
      max-width: 115rem;

      &--label {
        font-family: Poppins-Medium, sans-serif;
        font-size: 3.6rem;
        font-weight: 500;
        width: 87rem;
        line-height: 3.8rem;
        margin-bottom: 0.8rem;
        color: $color-primary;
        padding-bottom: 0.8rem;
      }

      &--subtext {
        font-size: 1.8rem;
        width: 87rem;
        font-family: Roboto, sans-serif;
        color: $color-dark-black;
      }
    }
  }

  .stepsContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin-top: 3.2rem;
  }

  .application-steps {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    max-width: 115rem;

    .form {
      &__wrapper {
        width: 65%;
      }
    }

    .skip-button-link {
      margin-top: 3.2rem;
      display: flex;
      width: 30.4rem;
    }
  }

  &-footer {
    width: 100vw;
  }

  @include styles-for-device-size(xs) {
    $application-left-padding: 1.6rem 1.6rem;

    &-header {
      &__clickables {
        padding: $application-left-padding;
        min-height: 0;
        flex-direction: row;
        justify-content: space-between;
        margin: 0;
        align-items: center;

        .section-tab {
          font-size: 1.2rem;
        }
      }
    }

    &-main {
      &__question {
        padding: 0 1.6rem 1.6rem;
        line-height: 3.2rem;
        width: 100%;

        &--label {
          width: 100%;
          font-size: 2.4rem;
          line-height: 2.8rem;
        }

        &--subtext {
          width: 100%;
          font-size: 1.6rem;
          line-height: 1.8rem;
        }
      }

      &__skip {
        margin: 0.8rem auto;
      }

      .Toastify__toast-theme--colored.Toastify__toast--error {
        border-radius: 0.4rem;
      }

      .Toastify__toast-container {
        width: auto;
        max-width: 94%;
        margin-left: 3%;
        height: auto;
        display: flex;
        justify-content: center;
      }

      .Toastify__toast-container--top-right {
        right: 0;
      }
    }

    .application-steps {
      padding: 0 3.6rem 1.6rem;

      .form {
        &__wrapper {
          width: 100%;
        }
      }

      .content {
        padding-bottom: 26.6rem;
      }

      .skip-button-link {
        width: 100%;
      }
    }

    .footer {
      position: sticky;
      bottom: -13.5rem;
      height: 20rem;
    }
  }
}

.loader-dashboard {
  .loader {
    margin-top: 19.2rem;

    &__message1,
    &__message2 {
      margin: 0;
      font-family: Poppins, sans-serif;
      color: $color-primary;
      font-size: 2.4rem;
    }

    &__message1 {
      margin-top: 4rem;
    }
  }
}

@include styles-for-device-size(sm) {
  .loader-dashboard {
    .loader {
      margin-top: 14.2rem;

      &__message1,
      &__message2 {
        max-width: 26.8rem;
        font-size: 1.6rem;
      }

      &__message1 {
        margin-top: 3.2rem;
      }
    }
  }
}
