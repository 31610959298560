@import "assets/styles/main";

.property-section {
  .properties {
    .section-subheader {
      margin-top: 3.2rem;
      margin-bottom: 1.6rem;
    }

    .no-properties {
      margin-top: 3.2rem;
      margin-bottom: 3.2rem;
      font-family: Roboto, sans-serif;
      font-size: 1.4rem;
      font-style: italic;
      color: $color-gray-bg;
    }

    .primary-property {
      margin-bottom: 2.4rem;
    }

    .additional-properties {
      margin-bottom: 3.2rem;

      &-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1.6rem;
      }

      .property-details {
        width: 40.5rem;
      }
    }

    .add-new-property-container {
      margin-bottom: 3.2rem;
    }

    &:not(:last-of-type) {
      border-bottom: 0.1rem solid $color-light-gray-bg;
    }
  }

  @include styles-for-device-size(md) {
    .properties {
      .additional-properties {
        &-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 0;
          column-gap: 0;

          .property-details:not(:last-of-type) {
            margin-bottom: 1.6rem;
            width: 90%;
          }
        }
      }
    }
  }
}
