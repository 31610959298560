@import "assets/styles/abstracts/variables";
@import "assets/styles/abstracts/mixins";

.welcome {
  display: flex;
  flex-direction: column;
  width: 82.6rem;
  min-height: 46rem;
  box-shadow: 0 0.3rem 0.6rem $color-gray-bg2;
  background-color: $color-white;
  padding: 0 3.2rem;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 7rem;
    width: 64.7rem;
  }

  &__logo {
    margin-top: 1rem;
    width: 13.5rem;
    height: 13.5rem;
  }

  &__text,
  &__text--max_height {
    width: 66.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    line-height: 1;

    &--p1,
    &--p1-center {
      color: $color-primary;
      font-size: 2.4rem;
      font-family: Poppins, sans-serif;
      margin-bottom: 2.9rem;
      font-weight: 600;
    }

    &--p1 {
      max-width: 57rem;
    }

    &--p2,
    &--p3 {
      font-size: 1.8rem;
      font-family: Poppins, sans-serif;
      color: $color-dark-black;
      width: 50rem;
    }

    &--p2 {
      margin-bottom: 3rem;
    }

    &--p4 {
      font-size: 1.6rem;
      font-family: Roboto, sans-serif;
      color: #333;
      line-height: 1.5;
    }
  }

  &__button {
    margin-bottom: 3.8rem;
  }

  .btn--large {
    font-size: 1.6rem;
    font-family: Poppins, sans-serif;
    width: 30.4rem;
    height: 5.2rem;
    color: $color-light-blue-primary;
  }
}

@include styles-for-device-size(xs) {
  .welcome {
    height: 100vh;

    &__content {
      width: 30.4rem;
      margin-top: 3rem;
    }

    &__logo {
      &--content {
        width: 10.4rem;
      }
    }

    &__text,
    &__text--max_height {
      line-height: 1.5;
      width: inherit;
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;

      &--p1,
      &--p1-center {
        font-size: 2rem;
        font-family: Poppins-SemiBold, sans-serif;
        margin-bottom: 1.8rem;
      }

      &--p1-center {
        text-align: center;
      }

      &--p2 {
        font-size: 1.4rem;
        margin-bottom: 1.8rem;
        width: auto;
      }

      &--p3 {
        width: auto;
        font-size: 1.4rem;
      }
    }

    &__text--max_height {
      height: 14rem;
    }

    &__button {
      margin-top: 1.8rem;
      margin-left: 0;
      margin-bottom: 0;

      .btn--large {
        font-size: 1.6rem;
        display: flex;
        justify-content: center;
        text-align: center;
        width: 30.4rem;
        height: 5.2rem;
        padding: 1.8rem;
        margin-top: 7rem;
      }
    }
  }
}