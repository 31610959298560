.center-text {
  text-align: center;
}

.no-margin-top {
  margin-top: 0;
}

.margin-bottom-small {
  margin-bottom: 1.5rem;
}

.margin-bottom-medium {
  margin-bottom: 4rem;
}

.margin-bottom-big {
  margin-bottom: 8rem;
}

.margin-top-small {
  margin-top: 1.5rem;
}

.margin-top-medium {
  margin-top: 4rem;
}

.margin-top-big {
  margin-top: 8rem;
}

.margin-small {
  margin: 0.5rem;
}

.margin-auto {
  margin: auto;
}

.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}