@import "assets/styles/main";

.sharedDashboardWrapper {
  .shared-dashboard {
    &__container,
    &__document-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin: 0 auto;
      min-height: 70vh;

      &__header {
        display: flex;
        align-self: self-start;
        margin-top: 6.4rem;
        margin-bottom: 3rem;
        gap: 0.8rem;

        &--icon {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          background: $color-primary;
          color: $color-white;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;

          .table-icon {
            font-size: 1.4rem;
          }
        }

        &--text {
          font-family: Poppins-SemiBold, sans-serif;
          color: $color-primary;
          font-size: 2.4rem;
        }
      }
    }

    &__container {
      max-width: 115rem;
    }

    &__document-container {
      max-width: 128rem;
    }

    .advertisement {
      .dashboard-insurance__img {
        width: 26.9rem;
        height: 16.76rem;
        object-fit: cover;
        margin-left: 9.6rem;
        margin-top: 1.6rem;
      }

      .dashboard-insurance__text {
        margin-left: 4.1rem;

        &--text1 {
          font-size: 1.6rem;
          font-family: Poppins-bold, sans-serif;
          color: $color-primary;
          margin-top: 2.4rem;
        }

        &--text2 {
          line-height: 2rem;
          font-family: Roboto, sans-serif;
          font-weight: 400;
          color: $color-dark-black;
          height: 4rem;
          width: 76.4rem;
        }
      }

      .dashboard-insurance__subtext {
        margin-top: 1.9rem;
        margin-left: 4.1rem;
        color: $color-dark-black;
        font-family: Roboto-bold, sans-serif;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 2rem;
        height: 6rem;
        width: 77.4rem;
      }

      .dashboard-insurance__btn {
        width: 11rem;
        height: 3.4rem;
        font-family: Poppins, sans-serif;
        font-size: 1.4rem;
        padding: 0;
      }
    }

    .loader {
      width: 100%;
      height: 100%;
      justify-content: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: $color-pure-white;
      font-family: Poppins, sans-serif;

      &__header {
        margin: 0 0 2.4rem;
        font-size: 2.4rem;
        font-weight: $font-weight-semi-bold;
        max-width: 51.5rem;
      }

      &__message1,
      &__message2 {
        color: $color-black;
        margin-top: 2.4rem;
        line-height: 2.4rem;
      }

      &__message1 {
        font-size: 1.8rem;
        font-weight: bold;
        max-width: 32rem;
      }

      &__message2 {
        font-size: 1.6rem;
        font-weight: 400;
        margin-top: 1.6rem;
      }
    }
  }

  @include styles-for-device-size(sm) {
    .toggleTabsWrapper {
      &_toggleTabs {
        display: flex;
        flex-direction: row;
        margin-top: 3rem;
        gap: 1.65rem;
        padding-left: 1.65rem;

        &_tab {
          font-size: 14px;
          font-weight: 500;
          text-decoration: none;
          color: $color-gray-bg2;
          position: relative;
          z-index: 2;

          &.active {
            color: $color-light-blue-secondary;
            border-bottom: $color-light-blue-secondary;
          }

          &.highlight{
            position: relative;

            &::before {
              content: "";
              position: absolute;
              top: -2rem;
              bottom: -0.4rem;
              left: -1.3rem;
              right: -1.3rem;
              background-color: white;
              border: 0.5rem solid $color-light-blue-secondary;
              height: 5.8rem;
              z-index: -1;
            }

            &.no-border::after {
              content:none;
            }
          }

          &.active::after {
            content: "";
            position: absolute;
            bottom: -0.6rem;
            left: 0;
            width: 100%;
            border-bottom: 0.4rem solid $color-light-blue-secondary;
          }
        }
      }

      &_line {
        margin-top: 0.6rem;
        width: 100%;
        height: 1px;
        background-color: $color-light-gray-three;
      }
    }

    .shared-dashboard {
      &__container {
        width: 95%;

        .modal__body {
          padding: 2rem;
        }

        &__header {
          margin-top: 4rem;
          margin-bottom: 0.8rem;
        }
      }

      &__document-container{
        width: 91%;
      }

      .advertisement {
        .dashboard-insurance__img {
          min-width: 90%;
          margin-left: 0;
          height: 26.34rem;
        }

        .dashboard-insurance__text {
          text-align: left;
          margin-left: 2.1rem;

          &--text1 {
            font-size: 1.8rem;
          }

          &--text2 {
            width: 90%;
            font-size: 1.6rem;
            line-height: 2.4rem;
            height: 12rem;
            margin-top: 0.8rem;
          }
        }

        .dashboard-insurance__subtext {
          width: 92%;
          text-align: left;
          margin-left: 2.1rem;
          margin-top: 2.4rem;
          height: fit-content;
          margin-bottom: 3.2rem;
        }

        .advertisemrnt-footer {
          display: block;
        }

        .dashboard-insurance__btn {
          min-width: 14.2rem;
          min-height: 3rem;
          margin-top: 2.4rem;

          &:hover {
            background-color: $color-light-blue-secondary;
            color: $color-white;
          }
        }
      }

      .loader {
        &__header,
        &__message1,
        &__message2 {
          max-width: 28.4rem;
        }

        &__header {
          font-size: 1.8rem;
          max-width: 30.4rem;
        }

        &__message1 {
          font-size: 1.6rem;
        }

        &__message2 {
          font-size: 1.4rem;
          margin-bottom: 12rem;
        }
      }

      .footer {
        margin-top: 17rem;
        position: sticky;
        bottom: -13.5rem;
        height: 20rem;
      }
    }
  }
}
