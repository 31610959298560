@import "assets/styles/main";

.voaInfo-wrapper {
    max-width: 66.2rem;
    max-height: 26.8rem;
    display: flex;
    padding: 2.4rem 1.6rem;
    border: 1px solid $color-light-gray-bg;
    border-radius: 0.6rem;
    flex-direction: column;
    gap: 1.6rem;

    h1 {
        font-size: 1.8rem;
        margin: 0 1.6rem;
        font-weight: $font-weight-normal;
        color: $color-primary;

        span {
            font-weight: $font-weight-medium;
        }
    }

    .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1.6rem;
        align-items: center;

        .details-container {
            display: flex;
            flex-direction: column;
            padding: 0.8rem 0;
            gap: 1.6rem;

            img {
                width: 14rem;
                height: 11.9rem;
            }

            .details {
                display: flex;
                flex-direction: row;
                gap: 1.6rem;

                img {
                    width: 1.2rem;
                    height: 1.6rem;
                }

                .point {
                    display: flex;
                    flex-direction: column;
                    font-size: 1.6rem;
                    font-family: Roboto, sans-serif;
                    color: $color-dark-black;

                    p {
                        font-weight: $font-weight-normal;
                    }

                    span {
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }
    }

    @include styles-for-device-size(sm) {
        max-height: none;

        h1 {
            text-align: center;
            max-width: 30rem;
        }

        .info {
            padding: 0;

            .details-container {
                gap: 1.6rem;
            }
        }
    }
}