@import "assets/styles/main";

.registration {
  margin: 0 auto auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__container {
    width: 144rem;
    padding: 0 9.6rem;
    display: flex;
    justify-content: space-between;
  }

  &__error {
    font-size: 1.6rem;
    font-family: Roboto, sans-serif;
    color: $color-danger;
    margin-top: 1.6rem;
  }

  &__form {
    width: 67rem;
    text-align: left;

    .row--hidden {
      display: none;
    }

    h1 {
      width: 51.8rem;
    }

    .form {
      margin-top: 2.4rem;
      width: 51.8rem;

      .input {
        padding: 1.5rem 2.4rem;

        &__wrapper {
          margin-left: 0;
        }
      }

      &__wrapper {
        margin: 0;
        width: 79.02%;
        gap: 1.2rem;

        .password-input-checker {
          .input {
            padding: 1.5rem 3.6rem 1.5rem 2.4rem;
          }
        }
      }

      .input-container {
        margin-bottom: 1.4rem;

        .input-label__wrapper label {
          margin-left: 0;
        }
      }
    }
  }

  &__header {
    font-size: 3.2rem;
    font-family: Poppins, sans-serif;
    color: $color-primary;
    font-weight: 500;
    line-height: 3.8rem;
  }

  &__button {
    width: 30.4rem;
  }

  &__information {
    width: 49rem;
    text-align: left;
  }

  &__text1 {
    font-size: 2.4rem;
    line-height: 4.2rem;
    font-weight: 400;
    font-family: Poppins, sans-serif;
  }

  &__text2 {
    font-size: 1.8rem;
    font-family: Poppins, sans-serif;
    padding: 1rem 0;
  }

  &__text3 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    font-family: Roboto, sans-serif;
    margin-left: 1.7rem;

    li {
      margin-bottom: 0.8rem;
    }
  }

  &__graphic {
    width: 42.4rem;
    height: 32.1rem;
    margin-top: 4.8rem;
    margin-left: 5.7rem;
  }

  ::-ms-reveal {
    display: none;
  }

  @include styles-for-device-size(xs) {
    &__container {
      width: 100%;
      padding: 2.4rem 2.5rem;
    }

    &__error {
      padding: 0 1.4rem;
    }

    &__header {
      line-height: 3rem;
    }

    &__form {
      width: 100%;
      text-align: center;

      h1 {
        font-size: 2.4rem;
        width: 100%;
      }

      .form {
        width: 100%;
        margin: 2.4rem 0 0;

        &__wrapper {
          margin: 0;
          width: 30.4rem;
          gap: 1.2rem;
          align-self: center;
        }

        .input__wrapper {
          margin: 1rem 0;
        }
      }
    }
  }
}