@import "assets/styles/main";

.version__container {
  margin-top: 3.2rem;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  .listed__items {
    font-size: 2.4rem;

    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }
}
