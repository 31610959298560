@import "assets/styles/main";

.card {
  height: fit-content;
  box-shadow: 0 0.3rem 0.6rem $color-gray-bg2;
  border-radius: 1rem;
  background-color: $color-white;
  animation: slide-out 0.5s;

  &__header {
    margin-left: 2.8rem;
    display: flex;

    p {
      font-size: 2.4rem;
      margin-top: 3.2rem;
      color: $color-primary;
      font-family: Poppins-Medium;
    }
  }

  &__footer {
    margin-top: 2.5rem;
    border-top: 0.1rem solid $color-light-gray-bg;
  }

  @keyframes slide-out {
    from {
      margin-top: 100%;
      height: 300%;
    }

    to {
      margin-top: 0%;
      height: 100%;
    }
  }
}

@include styles-for-device-size(sm) {
  .card {
    height: fit-content;
    width: 96% !important;
    align-items: center;

    &__header {
      p {
        margin-top: 4rem;
        width: 25rem;
        font-size: 2rem;
        text-align: left;
      }
    }
  }
}
