@import "assets/styles/abstracts/variables";

.radioButtonGroup {
    display: flex;
    align-items: center;
    height: 2rem;
    padding: 0;
    text-align: left;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
    color: $color-primary;
    white-space: nowrap;
    gap: 1.6rem;

    .buttonLabelWrapper{
        display: flex;
        align-items: center;
    }

    label {
        margin-left: 0.8rem;
    }

    input[type="radio"] {
        appearance: none;
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 0.9rem;
        box-shadow: 0 0 0 0.1rem $color-primary;
        padding: 0;
    }

    input[type="radio"]:checked{
        background-color: $color-primary;
        border: 0.25rem solid $color-white;
    }
}