@import "assets/styles/main";

.single-choice-with-data {
  .options {
    margin: 2rem 0;

    &__button {
      margin: 0 0 1.6rem;
      width: 30.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.active {
        background-color: $color-secondary;
      }
    }
  }

  @include styles-for-device-size(sm) {
    min-width: 100%;
    min-height: 58vh;

    .data__table__row{
      width: 100%;
    }

    .options {
      &__button {
        width: 100%;
      }
    }
  }
}
