@import "assets/styles/main";

.welcome-page {
  .welcome-container {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 92%;
    margin-left: 4%;

    &_title {
      font-size: 4.8rem;
      color: $color-primary;
      font-family: Poppins-Medium, sans-serif;
      margin-bottom: 3.2rem;
      text-align: center;
    }

    &_description {
      color: $color-dark-black;
      font-size: 1.8rem;
      font-family: Roboto, sans-serif;
      line-height: 2.4rem;
      margin-bottom: 3rem;
      text-align: center;
      max-width: 115rem;
      width: 56rem;
    }

    &_options-header {
      color: $color-primary;
      text-align: center;
      font-size: 1.8rem;
      font-family: Roboto, sans-serif;
      margin-bottom: 3.2rem;
      font-weight: $font-weight-semi-bold;
    }

    .options-container {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      margin-bottom: 3rem;
    }

    .option-template {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $color-off-white;
      border-radius: 5px;
      border: 1px solid $color-light-gray-bg;
      width: 56.3rem;
      height: 15.3rem;
      position: relative;
      

      &:not(:last-child) {
        margin-right: 1rem;
      }

      &:hover,
      &_active {
        border: 1px solid $color-secondary;
      }

      &_title {
        color: $color-primary;
        font-size: 1.8rem;
        font-family: Poppins-Medium, sans-serif;
        margin-top: 2.363rem;
        margin-bottom: 2.4rem;
      }

      .item1-title{
        position: absolute;
        left:2.5rem;
      }

      .item2-title{
        position: absolute;
        left:3.5rem;
      }

      .item1-image{
        img{
          width: 5rem;
          height: 5rem;
          position: absolute;
          left:5.5rem;
          top: 5.5rem;
        }
      }

      .item2-image{
        img{
          width: 3.7rem;
          height: 4.9rem;
          position: absolute;
          left:6.5rem;
          top: 5.5rem;
        }
      }

      &_description {
        text-align: center;
        font-size: 1.6rem;
        height: 7rem;
        font-family: Roboto-Regular, sans-serif;
        color: $color-dark-black;
        line-height: 1.5;
        text-shadow: 0.1px 0 0 $color-dark-black;
      }

      &__container {
        display: flex;
        margin-top: 1.6rem;
      }

      .item1-container{
        position: absolute;
        top:10rem;
        left:5rem;
      }

      .item2-container{
        position: absolute;
        top:10rem;
        left:5rem;
      }

      &__wrapper {
        display: flex;
      }

      .item1-description {
        font-family: Roboto, sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        width: 37rem;
        height: inherit;
        border-left: 1px solid $color-light-gray-bg;
        margin-left: 14rem;
        padding-left: 2rem;
        padding-top: 4rem;
      }

      .item2-description {
        font-family: Roboto, sans-serif;
        font-size: 1.8rem;
        font-weight: 400;
        width: 37rem;
        height: inherit;
        border-left: 1px solid $color-light-gray-bg;
        margin-left: 14rem;
        padding-left: 3rem;
        padding-top: 5rem;
      }

      &_estimation-time {
        display: flex;
        align-items: end;
        text-align: center;
        color: $color-primary;
        font-size: 1.6rem;
        margin-bottom: 2.4rem;

        &--number {
          font-size: 1.6rem;
          font-weight: bolder;
          color: $color-primary;
          margin-top: .2rem;
        }

        &--mins {
          font-size: 1.4rem;
          color: $color-primary;
          margin-top: 0.4rem;
        }
      }

      &_icon {
        font-size: 2rem;
        color: $color-primary;
      }
    }

    .btn--primary {
      font-size: 1.5rem;
      width: 30.4rem;
      height: 5.2rem;
    }

    @include styles-for-device-size(xs) {
      margin: 4rem 1.5rem 0;

      &_title {
        font-size: 2.4rem;
        margin-bottom: 2rem;
      }

      &_description {
        max-height: 20rem;
        width: 30rem;
        font-size: 1.6rem;
        line-height: 2.2rem;
        text-align: center;
      }

      &_options-header {
        margin-bottom: 2.4rem;
      }

      .options-container {
        flex-direction: column;
        gap: 2.5rem;
      }

      .option-template {
        margin-left: 3%;
        width: 31.4rem;
        height: 9.6rem;
        position: relative;
        box-shadow: $color-black-shadow 0 3px 3px 1px;
        align-items: flex-start;

        &_title {
          font-size: 1.4rem;
          margin-top: .5rem;
          padding-left: .5rem;
        }

        .item1-title {
          position: absolute;
          left: .5rem;
        }

        .item2-title {
          position: absolute;
          left: 1rem;
        }

        .item1-description {
          border-left: 1px solid $color-light-gray-bg;
          width: 19.5rem;
          height: 9.6rem;
          font-size: 1.4rem;
          margin-left: -1rem;
          padding-left: .5rem;
          padding-top: .5rem;
          text-indent: 1rem;
        }

        .item2-description {
          border-left: 1px solid $color-light-gray-bg;
          width: 19.4rem;
          height: 9.6rem;
          font-size: 1.4rem;
          margin-left: -1rem;
          padding-top: 2rem;
          padding-left: 1rem;
        }

        .item1-image {
          img{
            position: absolute;
            left:4.3rem;
            top: 3.5rem;
            width: 2.4rem;
            height: 2.4rem;
          }
        }

        .item2-image {
          img{
            position: absolute;
            left:4.5rem;
            top: 3.5rem;
            width: 1.8rem;
            height: 2.4rem;
          }
        }

        &_description {
          font-size: 1.4rem;
          position: absolute;
          padding-top: 1rem;
          padding-left: 1.5rem;
          left: 12rem;
        }

        &__container {
          margin-top: 0.5rem;
          margin-left: 1rem;
        }

        .item1-container{
          position: absolute;
          top:6rem;
          left:2rem;
        }

        .item2-container{
          position: absolute;
          top:6rem;
          left:1.5rem;
        }

        &_icon{
          width: 1.5rem;
          height: 1.2rem;
          margin-top: .6rem;
        }

        &_estimation-time{
          &--number{
            font-size: 1.4rem;
            margin-top: .4rem;
          }
          
          &--mins{
            font-weight: $font-weight-semi-bold;
          }
        }

      }

      button.btn {
        width: 31.4rem;
        height: 5.2rem;
      }
    }
  }

  @include styles-for-device-size(xs) {
    .footer {
      margin-top: 12rem;
      position: sticky;
      bottom: -13.5rem;
      height: 20rem;
    }
  }
}
