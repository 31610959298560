@import "assets/styles/main";

.single-choice {
  &.single-choice-dropdown {
    width: 30.4rem;

    .btn {
      width: 30.4rem;
      margin-top: 2rem;
    }

    .disclaimer {
      width: 70rem;

      &--text {
        width: 85%;
        margin-top: .8rem;
        font-size: 1.4rem;
      }
    }

    @include styles-for-device-size(sm) {
      .disclaimer {
        width: 100%;

        &--text {
          width: 100%;
        }
      }
    }
  }

  &-button {
    margin-bottom: 1.6rem;
    width: 30.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: $color-secondary;
    }

    &.retain-focus {
      color: $color-white;
      background-color: $color-secondary;
    }
  }

  .textarea-container {
    .textarea {
      width: calc(100% - 10rem);

      @include styles-for-device-size(xs) {
        width: calc(100% - 2rem);
      }
    }
  }

  @include styles-for-device-size(sm) {
    min-width: 100%;
    min-height: 58vh;

    &-button {
      width: 100%;
    }
  }
}
